/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useMemo } from "react";

import Sidebar from "../../components/Sidebar";
import { LogoutOutlined, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import axios from "axios";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "../../components/DataTable";
import Alerts from "../../components/Alerts";
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';

export default function Users() {

  const [data, setData] = useState([]);
  const originalDataRef = useRef([]);
  const [message, setMessage] = useState("");

  let records = [];
  let querySnapshotSize = 0;

  let i = 0;

  let navigate = useNavigate();

  const formatData = ({
    id,
    channelPartnerRefferalCode,
    channelPartnerRefferalId,
    email,
    mobile,
    password,
    city,
    licence,
    activationDate,
    personalName,
    businessName,
    businessCategory,
    deviceID,
    isDisabled
  }) => {

    records.push({
      id,
      channelPartnerRefferalCode,
      channelPartnerRefferalId,
      personalName,
      businessName,
      businessCategory,
      mobile,
      email,
      password,
      city,
      licence,
      dateOfJoining: activationDate,
      deviceID,
      isDisabled
    });

    if (++i === querySnapshotSize) {

      //DOJ FORMAT START
      originalDataRef.current = records.sort(function (a, b) {
        return new Date(b.dateOfJoining) - new Date(a.dateOfJoining);
      });

      originalDataRef.current = originalDataRef.current.map(d => {
        return {
          ...d, dateOfJoining: moment(d.dateOfJoining).utc()
            .tz("Asia/Kolkata")
            .format("DD-MM-YYYY")
        }
      })
      //DOJ FORMAT END

      //EXPIRE FORMAT START
      originalDataRef.current = originalDataRef.current.map(d => {
        return {
          ...d, expiryDate: moment(d.expiryDate).utc()
            .tz("Asia/Kolkata")
            .format("DD-MM-YYYY")
        }
      })
      //EXPIRE FORMAT END

      setData(originalDataRef.current);
      // setData(records);

    }
  }

  const fetchUsers = async () => {

    const q = query(collection(db, "Users"), orderBy("activationDate", "desc"));
    const snapshots = await getDocs(q);
    querySnapshotSize = snapshots.docs.length;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      if (doc.data()) {
        let {
          channelPartnerRefferalCode = "",
          channelPartnerRefferalId = "",
          email,
          mobile,
          password,
          city,
          licence,
          activationDate,
          personalName,
          businessName,
          businessCategory = "",
          deviceID = "",
          isDisabled = false,
        } = doc.data();

        if (activationDate) {
          formatData({
            id: doc.id,
            channelPartnerRefferalCode,
            channelPartnerRefferalId,
            email,
            mobile,
            password,
            city,
            licence,
            activationDate: activationDate.toDate(),
            personalName,
            businessName,
            businessCategory,
            deviceID,
            isDisabled
          });
        } else {
          console.log("activationDate", activationDate, doc.id)
        }
      }
    });
  };

  const LogOut = (user) => {

    let data = JSON.stringify({
      "email": user.email,
      "password": user.password,
      "deviceID": user.deviceID
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://tallykonnect.com/auth/mobilelogout',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setMessage(response.data.message);

        fetchUsers();
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const updateUserStatus = (profile) => {

    let data = JSON.stringify({
      "uid": profile.id,
      "isDisabled": !profile.isDisabled
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://tallykonnect.com/auth/userstatus',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setMessage(response.data.message);
        setData([]);
        fetchUsers();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const columns = useMemo(
    () => [
      { field: "channelPartnerRefferalCode", headerName: "CP Code", minWidth: 210, align: "left" },
      { field: "channelPartnerRefferalId", headerName: "CP Referal Id", minWidth: 210, align: "left" },
      { field: "businessName", headerName: "Business", minWidth: 210, align: "left" },
      { field: "businessCategory", headerName: "Business Category", minWidth: 210, align: "left" },
      { field: "personalName", headerName: "Name", minWidth: 210, align: "left" },
      { field: "email", headerName: "Email", minWidth: 210, align: "left" },
      { field: "mobile", headerName: "Mobile", minWidth: 100, align: "left" },
      { field: "password", headerName: "Password", minWidth: 100, align: "left" },
      { field: "deviceID", headerName: "Device", minWidth: 100, align: "left" },
      { field: "city", headerName: "City", minWidth: 100, align: "left" },
      {
        field: "dateOfJoining",
        headerName: "D.O.J",
        minWidth: 120,
        align: "left",
      },
      {
        field: "isDisabled",
        headerName: "Status",
        minWidth: 120,
        align: "left",
      },
      {
        field: "licence",
        headerName: "Licence",
        minWidth: 120,
        align: "left",
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        headerName: "Actions",
        minWidth: 170,
        align: "right",
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              params.row && params.row.isDisabled ? (
                <VoiceOverOffIcon style={{ color: "red" }} />
              ) : (
                <SpatialAudioOffIcon style={{ color: "green" }} />
              )
            }
            label="Disable"
            onClick={() => updateUserStatus(params.row)}
          />,
          <GridActionsCellItem
            icon={<Visibility />}
            label="View"
            onClick={() => navigate(
              "/userprofile", { state: { ...params.row } }
            )}
          />,
          <GridActionsCellItem
            icon={<LogoutOutlined />}
            label="Logout"
            onClick={() => LogOut(params.row)}
          />,
        ]
      },
    ], [navigate]);

  useEffect(() => {

    fetchUsers();
  }, [])

  return (
    <div className="flex h-screen w-screen">
      <Sidebar />
      <div className="body w-64 flex-1 flex-col bg-white p-8">
        <h1 className="mb-4 rounded bg-indigo-600 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
          Users
        </h1>
        <Alerts alertType="Info">{message}</Alerts>
        {data.length >= 0 ? (
          <DataTable columns={columns} data={data} />
        ) : (
          <p className="text-xl font-semibold text-red-500 ">
            no records found
          </p>
        )}
      </div>
    </div>
  );
}
