import React from "react";
import { Group, Dashboard, ExitToApp, CellTowerOutlined, Report, Mail, Phone, BorderAllRounded, GroupAddOutlined } from "@mui/icons-material";

import ExtensionIcon from "@mui/icons-material/Extension";
import { getAuth, signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import ReceiptIcon from "@mui/icons-material/Receipt";


function Sidebar() {
  const userInfo = localStorage?.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : { uid: "" };

  return (
    <div className="h-screen sidebar flex w-72 flex-col justify-between  bg-indigo-900 overflow-y-scroll">
      <ul className="space-y-4 p-4 text-white ">
        <li className="text-2xl font-semibold text-white">
          <Link to="/">ADMIN</Link>
        </li>
        <li>
          <Link to="/dashboard">
            <Dashboard />
            <span className="ml-4 uppercase">Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/plugin">
            <ExtensionIcon></ExtensionIcon>
            <span className="ml-4 uppercase">Plugin</span>
          </Link>
        </li>
        <li>
          <Link to="/plans">
            <ReceiptIcon />
            <span className="ml-4 uppercase">Plans</span>
          </Link>
        </li>
        <li>
          <Link to="/products">
            <ReceiptIcon />
            <span className="ml-4 uppercase">Products</span>
          </Link>
        </li>
        <li>
          <Link to="/order">
            <ReceiptIcon />
            <span className="ml-4 uppercase">Orders</span>
          </Link>
        </li>
        <li>
          <Link to="/mail">
            <Mail />
            <span className="ml-4 uppercase">Mail</span>
          </Link>
        </li>
        <li>
          <Link to="/message">
            <Phone />
            <span className="ml-4 uppercase">WhatsApp</span>
          </Link>
        </li>
        <li>
          <Link to="/users" >
            <Group />
            <span className="ml-4 uppercase">Users</span>
          </Link>
        </li>
        <li>
          <Link to="/channelpartner" >
            <Group />
            <span className="ml-4 uppercase">Channel Partner</span>
          </Link>
        </li>
        <li>
          <Link to="/contactus">
            <Group />
            <span className="ml-4 uppercase">Contact Us</span>
          </Link>
        </li>
        <li>
          <Link to="/demo">
            <Group />
            <span className="ml-4 uppercase">Demo</span>
          </Link>
        </li>
        <li>
          <Link to="/registers">
            <GroupAddOutlined />
            <span className="ml-4 uppercase">
              Mobile Registers
            </span>
          </Link>
        </li>
        <li>
          <Link to="/broadcast">
            <CellTowerOutlined />
            <span className="ml-4 uppercase">Broadcast</span>
          </Link>
        </li>
        <li>
          <Link to="/advertise">
            <BorderAllRounded />
            <span className="ml-4 uppercase">Advertise</span>
          </Link>
        </li>
        <li>
          <Link to="/testimonials">
            <Report />
            <span className="ml-4 uppercase">Testimonials</span>
          </Link>
        </li>
      </ul>
      <ul className="h-screen space-y-4 p-4 mt-4 text-white">
        {/* Footer Menu */}
        <li>
          <Link
            onClick={() => {
              const auth = getAuth();
              signOut(auth)
                .then(() => {
                  window.localStorage.clear("user");
                })
                .catch((error) => {
                  // An error happened.
                });
            }}
            to="/login"
          >
            <ExitToApp></ExitToApp>
            <span className="ml-4 uppercase">Sign Out</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
