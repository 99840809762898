import { getAuth, signOut } from "firebase/auth";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { db } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";
import FilterText from "../../utils/FilterText";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Visibility } from "@mui/icons-material";
import DataTable from "../../components/DataTable";
import moment from "moment-timezone";

const Card = (props) => {
  return (
    <div className="m-4 w-52 flex-1 rounded-md bg-gray-700 p-4 text-white">
      <h3 className="text-center uppercase">{props.title ?? ""}</h3>
      <p className="text-semibold overflow-auto text-center text-yellow-200">
        {props.body ?? ""}
      </p>
      <p className="text-center font-thin uppercase text-orange-400">
        {props.subtitle}
      </p>
    </div>
  );
};

const MultiCard = (props) => {
  return (
    <div className="m-4 w-52 flex-1 rounded-md bg-gray-700 p-4 text-white">
      <h3 className="text-center uppercase">{props.title ?? ""}</h3>
      <div className="flex flex-start gap-2">
        <p className="text-semibold overflow-auto text-center text-yellow-200">
          {props.body ?? ""}
        </p>
        <p className="text-center font-thin uppercase text-orange-400">
          {props.subtitle}
        </p>
      </div>
      <div className="flex flex-start gap-2">
        <p className="text-semibold overflow-auto text-center text-yellow-200">
          {props.body2 ?? ""}
        </p>
        <p className="text-center font-thin uppercase text-orange-400">
          {props.subtitle2}
        </p>
      </div>
      <div className="flex flex-start gap-2">
        <p className="text-semibold overflow-auto text-center text-yellow-200">
          {props.body3 ?? ""}
        </p>
        <p className="text-center font-thin uppercase text-orange-400">
          {props.subtitle3}
        </p>
      </div>
    </div>
  );
};

function Dashboard() {

  const [loading, setLoading] = useState();
  const [data, setData] = useState();
  const [userProfile, setUserProfile] = useState();
  const [error, setError] = useState();
  const [usersData, setUsersData] = useState([]);
  const [cpData, setCPData] = useState([]);

  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const [cpSize, setCPSize] = useState(0);
  const [uSize, setUSize] = useState(0);
  const [pendingContactUsData, setPendingContactUsData] = useState([]);
  const [followUpContactUsData, setFollowUpContactUsData] = useState([]);
  const [doneContactUsData, setDoneContactUsData] = useState([]);
  const [pendingDemoData, setPendingDemoData] = useState([]);
  const [followUpDemoData, setFollowUpDemoData] = useState([]);
  const [doneDemoData, setDoneDemoData] = useState([]);
  const originalContactUsPendingDataRef = useRef();
  const originalContactUsFollowUpDataRef = useRef();
  const originalContactUsDoneDataRef = useRef();
  const originalDemoPendingDataRef = useRef();
  const originalDemoFollowUpDataRef = useRef();
  const originalDemoDoneDataRef = useRef();

  const userColumns = [
    { field: "personalName", headerName: "Name", minWidth: 210, align: "left" },
    { field: "email", headerName: "Email", minWidth: 210, align: "left" },
    { field: "mobile", headerName: "Mobile", minWidth: 100, align: "left" },
    { field: "password", headerName: "Password", minWidth: 100, align: "left" },
    {
      field: "dateOfJoining",
      headerName: "D.O.J",
      minWidth: 120,
      align: "left",
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      headerName: "Actions",
      minWidth: 170,
      align: "right",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Visibility />}
          label="View"
          onClick={() => navigate(
            "/userprofile", { state: { ...params.row } }
          )}
        />
      ]
    },
  ];

  const CPColumns = [
    { field: "myReferalCode", headerName: "Referal Code", minWidth: 210, align: "left" },
    { field: "personalName", headerName: "Name", minWidth: 210, align: "left" },
    { field: "email", headerName: "Email", minWidth: 210, align: "left" },
    { field: "mobile", headerName: "Mobile", minWidth: 100, align: "left" },
    { field: "password", headerName: "Password", minWidth: 100, align: "left" },
    {
      field: "dateOfJoining",
      headerName: "D.O.J",
      minWidth: 120,
      align: "left",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      headerName: "Actions",
      minWidth: 170,
      align: "right",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Visibility />}
          label="View"
          onClick={() => navigate(
            "/channelpartnerprofile", { state: { ...params.row } }
          )}
        />
      ]
    },
  ];

  const changeDate = (date) => {
    let dateArray = [];
    let m = "";
    let d = "";
    let y = "";

    if (date.includes("-")) {

      dateArray = date.split("-");
      m = dateArray[1];
      d = dateArray[0];
      y = dateArray[2];
    } else if (date.includes("/")) {

      dateArray = date.split("/");
      m = dateArray[1];
      d = dateArray[0];
      y = dateArray[2];
    }

    return y + "/" + m + "/" + d
  }

  //TOTAL NO OF USERS
  const getAllUsers = async () => {
    let colRef = collection(db, "Users");
    let snapshots = await getDocs(colRef);
    let uSize = snapshots.size;

    setUSize(uSize);
  }

  //TOTAL NO OF RESELLERS
  const getAllChannelPartners = async () => {
    let colRef = collection(db, "Channel Partners");
    let snapshots = await getDocs(colRef);
    let cpSize = snapshots.size;

    setCPSize(cpSize);
  }

  //TOP TEN CONTACT US / DEMO
  const getPendingContactUs = async () => {
    const q = query(collection(db, "Websites", "Client", "ContactUs"), where("status", "==", "Pending"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      if (doc.data()) {
        let {
          businessName,
          city,
          email,
          mobile,
          erpType,
          personalName,
          pincode,
          profile,
          status = "",
          date,
          followupDate,
          attained
        } = doc.data()

        records.push({
          id: doc.id,
          businessName,
          city,
          email,
          mobile,
          erpType,
          personalName,
          pincode,
          profile,
          status,
          followupDate,
          attained,
          date: changeDate(date)
        });

      }

      if (++i === querySnapshotSize) {

        originalContactUsPendingDataRef.current = records.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });

        originalContactUsPendingDataRef.current = originalContactUsPendingDataRef.current.map(d => {
          return {
            ...d, date: moment(d.date).utc()
              .tz("Asia/Kolkata")
              .format("DD-MM-YYYY  hh:mm:ss A")
          }
        })

        originalContactUsPendingDataRef.current = originalContactUsPendingDataRef.current.map(d => {
          if (d.followupDate) {
            return {
              ...d, followupDate: moment(d.followupDate).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        originalContactUsPendingDataRef.current = originalContactUsPendingDataRef.current.map(d => {
          if (d.attained) {
            return {
              ...d, attained: moment(d.attained).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        setPendingContactUsData(originalContactUsPendingDataRef.current);
      }
    });
  }

  const getPendingDemo = async () => {

    const q = query(collection(db, "Websites", "Client", "Demo"), where("status", "==", "Pending"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      if (doc.data()) {
        let {
          name,
          email,
          mobile,
          dateTime,
          status,
          attained,
          executive,
          followupDate
        } = doc.data()

        records.push({
          id: doc.id,
          name,
          email,
          mobile,
          dateTime,
          status,
          attained,
          executive,
          followupDate,
        });
      }

      if (++i === querySnapshotSize) {


        originalDemoPendingDataRef.current = records.sort(function (a, b) {
          return new Date(b.dateTime) - new Date(a.dateTime);
        });

        originalDemoPendingDataRef.current = originalDemoPendingDataRef.current.map(d => {
          return {
            ...d, dateTime: moment(d.dateTime).utc()
              .tz("Asia/Kolkata")
              .format("DD-MM-YYYY h:mm:ss A"), originalDateTime: d.dateTime
          }
        })

        originalDemoPendingDataRef.current = originalDemoPendingDataRef.current.map(d => {
          if (d.followupDate) {
            return {
              ...d, followupDate: moment(d.followupDate).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        originalDemoPendingDataRef.current = originalDemoPendingDataRef.current.map(d => {
          if (d.attained) {
            return {
              ...d, attained: moment(d.attained).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        setPendingDemoData(originalDemoPendingDataRef.current);

      }
    });
  }

  const getFollowUpContactUs = async () => {

    const q = query(collection(db, "Websites", "Client", "ContactUs"), where("status", "==", "Follow Up"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      if (doc.data()) {
        let {
          businessName,
          city,
          email,
          mobile,
          erpType,
          personalName,
          pincode,
          profile,
          status = "",
          date,
          followupDate,
          attained
        } = doc.data()

        records.push({
          id: doc.id,
          businessName,
          city,
          email,
          mobile,
          erpType,
          personalName,
          pincode,
          profile,
          status,
          followupDate,
          attained,
          date: changeDate(date)
        });

      }

      if (++i === querySnapshotSize) {

        originalContactUsFollowUpDataRef.current = records.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });

        originalContactUsFollowUpDataRef.current = originalContactUsFollowUpDataRef.current.map(d => {
          return {
            ...d, date: moment(d.date).utc()
              .tz("Asia/Kolkata")
              .format("DD-MM-YYYY  hh:mm:ss A")
          }
        })

        originalContactUsFollowUpDataRef.current = originalContactUsFollowUpDataRef.current.map(d => {
          if (d.followupDate) {
            return {
              ...d, followupDate: moment(d.followupDate).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        originalContactUsFollowUpDataRef.current = originalContactUsFollowUpDataRef.current.map(d => {
          if (d.attained) {
            return {
              ...d, attained: moment(d.attained).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        setFollowUpContactUsData(originalContactUsFollowUpDataRef.current);
      }
    });

  }

  const getDoneContactUs = async () => {

    const q = query(collection(db, "Websites", "Client", "ContactUs"), where("status", "==", "Done"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      if (doc.data()) {
        let {
          businessName,
          city,
          email,
          mobile,
          erpType,
          personalName,
          pincode,
          profile,
          status = "",
          date,
          followupDate,
          attained
        } = doc.data()

        records.push({
          id: doc.id,
          businessName,
          city,
          email,
          mobile,
          erpType,
          personalName,
          pincode,
          profile,
          status,
          followupDate,
          attained,
          date: changeDate(date)
        });

      }

      if (++i === querySnapshotSize) {

        originalContactUsDoneDataRef.current = records.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        });

        originalContactUsDoneDataRef.current = originalContactUsDoneDataRef.current.map(d => {
          return {
            ...d, date: moment(d.date).utc()
              .tz("Asia/Kolkata")
              .format("DD-MM-YYYY  hh:mm:ss A")
          }
        })

        originalContactUsDoneDataRef.current = originalContactUsDoneDataRef.current.map(d => {
          if (d.followupDate) {
            return {
              ...d, followupDate: moment(d.followupDate).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        originalContactUsDoneDataRef.current = originalContactUsDoneDataRef.current.map(d => {
          if (d.attained) {
            return {
              ...d, attained: moment(d.attained).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        setDoneContactUsData(originalContactUsDoneDataRef.current);
      }
    });

  }

  const getFollowUpDemo = async () => {
    const q = query(collection(db, "Websites", "Client", "Demo"), where("status", "==", "Follow Up"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      if (doc.data()) {
        let {
          name,
          email,
          mobile,
          dateTime,
          status,
          attained,
          executive,
          followupDate
        } = doc.data()

        records.push({
          id: doc.id,
          name,
          email,
          mobile,
          dateTime,
          status,
          attained,
          executive,
          followupDate,
        });
      }

      if (++i === querySnapshotSize) {


        originalDemoFollowUpDataRef.current = records.sort(function (a, b) {
          return new Date(b.dateTime) - new Date(a.dateTime);
        });

        originalDemoFollowUpDataRef.current = originalDemoFollowUpDataRef.current.map(d => {
          return {
            ...d, dateTime: moment(d.dateTime).utc()
              .tz("Asia/Kolkata")
              .format("DD-MM-YYYY h:mm:ss A"), originalDateTime: d.dateTime
          }
        })

        originalDemoFollowUpDataRef.current = originalDemoFollowUpDataRef.current.map(d => {
          if (d.followupDate) {
            return {
              ...d, followupDate: moment(d.followupDate).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        originalDemoFollowUpDataRef.current = originalDemoFollowUpDataRef.current.map(d => {
          if (d.attained) {
            return {
              ...d, attained: moment(d.attained).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        setFollowUpDemoData(originalDemoFollowUpDataRef.current);

      }
    });
  }

  const getDoneDemo = async () => {
    const q = query(collection(db, "Websites", "Client", "Demo"), where("status", "==", "Done"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      if (doc.data()) {
        let {
          name,
          email,
          mobile,
          dateTime,
          status,
          attained,
          executive,
          followupDate
        } = doc.data()

        records.push({
          id: doc.id,
          name,
          email,
          mobile,
          dateTime,
          status,
          attained,
          executive,
          followupDate,
        });
      }

      if (++i === querySnapshotSize) {


        originalDemoDoneDataRef.current = records.sort(function (a, b) {
          return new Date(b.dateTime) - new Date(a.dateTime);
        });

        originalDemoDoneDataRef.current = originalDemoDoneDataRef.current.map(d => {
          return {
            ...d, dateTime: moment(d.dateTime).utc()
              .tz("Asia/Kolkata")
              .format("DD-MM-YYYY h:mm:ss A"), originalDateTime: d.dateTime
          }
        })

        originalDemoDoneDataRef.current = originalDemoDoneDataRef.current.map(d => {
          if (d.followupDate) {
            return {
              ...d, followupDate: moment(d.followupDate).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        originalDemoDoneDataRef.current = originalDemoDoneDataRef.current.map(d => {
          if (d.attained) {
            return {
              ...d, attained: moment(d.attained).utc()
                .tz("Asia/Kolkata")
                .format("DD-MM-YYYY hh:mm:ss A")
            }
          } else {
            return d;
          }
        })

        setDoneDemoData(originalDemoDoneDataRef.current);

      }
    });
  }

  const originalDataRef = useRef([]);
  let records = [];
  let querySnapshotSize = 0;
  let i = 0;

  const formatData = ({
    id,
    email,
    mobile,
    password,
    activationDate,
    firstName,
    personalName,
    plan
  }) => {

    records.push({
      id,
      personalName: firstName ? firstName : personalName,
      mobile,
      email,
      password,
      dateOfJoining:
        activationDate !== ""
          ? moment(activationDate.toDate()).utc().format("YYYY-MM-DD")
          : "",
    });

    if (++i === querySnapshotSize) {

      //DOJ FORMAT START
      originalDataRef.current = records.sort(function (a, b) {
        return new Date(b.dateOfJoining) - new Date(a.dateOfJoining);
      });

      originalDataRef.current = originalDataRef.current.map(d => {
        return {
          ...d, dateOfJoining: moment(d.dateOfJoining).utc()
            .tz("Asia/Kolkata")
            .format("DD-MM-YYYY")
        }
      })
      //DOJ FORMAT END

      setUsersData(originalDataRef.current);
      // setData(records);

    }
  }

  //GET USERS
  const fetchUsers = async () => {
    const q = query(collection(db, "Users"), orderBy("activationDate", "desc"), limit(10));
    const snapshots = await getDocs(q);

    querySnapshotSize = snapshots.docs.length;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      let {
        email,
        mobile,
        password,
        activationDate,
        firstName,
        personalName,
        plan
      } = doc.data();

      formatData({
        id: doc.id,
        email,
        mobile,
        password,
        activationDate,
        firstName,
        personalName,
        plan
      });
    });
  };

  //GET CP
  const fetchChannellPartners = async () => {

    const q = query(collection(db, "Channel Partners"), orderBy("doj", "desc"), limit(10));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START
      let {
        email,
        mobile,
        password,
        doj,
        firstName = "",
        personalName = "",
        licenceInfo: { plan } = "",
        myReferalCode
      } = doc.data();

      if (doc.data()) {
        records.push({
          id: doc.id,
          personalName: firstName ? firstName : personalName,
          mobile,
          email,
          password,
          dateOfJoining:
            doj !== ""
              ? moment(Number(doj)).utc().format("DD-MM-YYYY")
              : "",
          myReferalCode
        });
      }

      if (++i === querySnapshotSize) {
        setCPData(records);
      }
    });
  };

  //GET USER PROFILE
  useEffect(() => {
    const init = async () => {
      try {
        let auth = getAuth();
        let docRef = doc(db, "Users", user.uid);
        let userDataRef = await getDoc(docRef);

        if (userDataRef.exists()) {
          let userData = userDataRef.data();

          if (userData.userType !== "Admin") {
            await signOut(auth);
            navigate("/login")
          }

        } else {
          await signOut(auth);
          navigate("/login")
        }
      } catch (error) {
        setError(FilterText(error.message))
      }
    }

    init();
    getAllUsers();
    getAllChannelPartners();
    getPendingContactUs();
    getPendingDemo();
    getFollowUpContactUs();
    getFollowUpDemo();
    getDoneContactUs();
    getDoneDemo();
    fetchUsers();
    fetchChannellPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="flex w-screen ">
      <Sidebar />

      <div className="flex flex-col overflow-y-scroll w-full h-screen">
        <div className="body flex-1 bg-green-50">
          <h1 className="mt-4 mb-4 text-center text-2xl font-semibold text-green-600">
            Welcome back {userProfile?.name}
          </h1>
          <div className="mt-11 flex flex-wrap justify-around">
            <Card title="USERS" body="TOTAL" subtitle={uSize} />
            <Card title="CHANNEL PARTNERS" body="TOTAL" subtitle={cpSize} />
            <MultiCard
              title="CONTACT US"
              body="PENDING"
              subtitle={pendingContactUsData.length}
              body2="FOLLOW UP"
              subtitle2={followUpContactUsData.length}
              body3="DONE"
              subtitle3={doneContactUsData.length}
            />
            <MultiCard
              title="DEMO"
              body="PENDING"
              subtitle={pendingDemoData.length}
              body2="FOLLOW UP"
              subtitle2={followUpDemoData.length}
              body3="DONE"
              subtitle3={doneDemoData.length}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-1 m-2">
          <div>
            <h1 className="text-white font-serif text-xl text-center bg-blue-400 p-1">LATEST 10 USERS</h1>
            {
              usersData.length > 0 && (
                <DataTable columns={userColumns} data={usersData} />
              )
            }
          </div>
          <div>
            <h1 className="text-white font-serif text-xl text-center bg-blue-400 p-1">LATEST 10 CHANNEL PARTNERS</h1>
            {
              cpData.length > 0 && (
                <DataTable columns={CPColumns} data={cpData} />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
