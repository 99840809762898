import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Logo from "../../pages/login/logo.png";
import Alerts from "../../components/Alerts";
import FilterText from "../../utils/FilterText";

function ResetPassword() {
  const [value, setValue] = useState(null);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);

  const handleChange = (e) => {
    setValue((preVal) => {
      return { ...preVal, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPending(true);

    const auth = getAuth();
    sendPasswordResetEmail(auth, value.email)
      .then(() => {
        setMessage("Check your mail to reset password");
        setPending(false);
      })
      .catch((error) => {
        setPending(false);
        setError(FilterText(error.message));
      });
  };

  return (
    <div className="flex min-h-screen flex-col justify-center bg-gray-100 py-12 px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={Logo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold  uppercase " style={{ color: "#055592" }}>
          Reset Password
        </h2>
        <p className="max-w mt-2 text-center text-sm text-gray-600">
          Already registered ?
          <Link
            to="/login"
            className="ml-2 font-medium  hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            style={{ color: "#055592" }}
          >
            Sign In
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-[400px]">
        <div className="rounded-lg bg-white py-8 px-6 shadow sm:px-10">
          <form
            className="mb-0 space-y-6"
            method="POST"
            onSubmit={handleSubmit}
          >
            <div>
              <label htmlFor="value">Email</label>
              <div className="mt-1 relative">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                  className="w-6 h-6 absolute top-2 left-1" style={{ color: "#055592" }}>
                  <path strokeLinecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
                </svg>
                <input
                  className="pl-8 p-2 "
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="value"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  required
                />
              </div>
            </div>

            <div className="flex justify-center items-center">
              <button
                disabled={pending}
                type="submit"
                className="flex w-[150px] justify-center  rounded-md border border-transparent  py-2 px-4 text-sm font-medium text-white shadow-sm "
                style={{ backgroundColor: "#055592" }}
              >
                {!pending ? "Reset" : "Wait..."}
              </button>

            </div>
            <div>
              {error && <Alerts alertType="Error">{error}</Alerts>}
              {message && <Alerts alertType="Success">{message}</Alerts>}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
