import { Upload } from '@mui/icons-material';
import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, serverTimestamp } from 'firebase/firestore';
import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import Sidebar from '../../components/Sidebar'
import { db, storage } from '../../firebase';
import FilterText from '../../utils/FilterText';

import moment from 'moment/moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import DataTable from "../../components/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid";

function Advertise() {

    const [Data, setData] = useState([]);

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const messageRef = useRef("");
    const fromRef = useRef("");
    const toRef = useRef("");
    const callToActionRef = useRef("");
    const visitUsRef = useRef("");

    const columns = [

        {
            field: "uploadDate",
            headerName: "Date",
            minWidth: 200,
            align: "left",
            format: (value) => value.toLocaleString("en-IN"),
        },
        {
            field: "from",
            headerName: "From",
            minWidth: 200,
            align: "right",
        },
        { field: "to", headerName: "To", minWidth: 200, align: "left" },
        { field: "callToAction", headerName: "Call to Action", minWidth: 200, align: "left" },
        { field: "Visit Us", headerName: "Visit Us", minWidth: 200, align: "left" },
        { field: "downloadURL", headerName: "Image", minWidth: 200, align: "left" },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDelete(params.row)}
                />,
            ]
        },
    ];

    const [isPending, setIsPending] = useState(false);
    const [Progress, setProgress] = useState(false);

    //HANDLE CRUD UPLOADS
    const handleUpload = (e) => {
        e.preventDefault();

        const FileInfo = e.target.files[0];

        const FileRef = ref(
            storage,
            `Advertise/${new Date().getTime()}`
        );

        const metaData = {
            contentType: FileInfo.type,
        };

        const UplodPlugin = uploadBytesResumable(FileRef, FileInfo, metaData);

        UplodPlugin.on(
            "state_changed",
            (snapshot) => {
                setIsPending(true);
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
            },
            (error) => {
                setIsPending(false);
                setError(FilterText(error.message));
            },
            () => {
                getDownloadURL(UplodPlugin.snapshot.ref).then(async (downloadURL) => {
                    //ADD NEW BUSY PLUGIN LINKS

                    handleCreate({
                        fileName: FileInfo.name,
                        uploadDate: serverTimestamp(),
                        from: fromRef.current.value,
                        to: toRef.current.value,
                        callToAction: callToActionRef.current.value,
                        visitUs: visitUsRef.current.value,
                        message: messageRef.current.value,
                        downloadURL: downloadURL
                    })

                });
            }
        );
    };

    const fetchAllAdvertise = async () => {
        const q = query(collection(db, "Websites", "Admin", "Advertise"), orderBy("uploadDate", "asc"));
        const snapshots = await getDocs(q);

        let records = [];
        let querySnapshotSize = snapshots.docs.length;
        let i = 0;

        if (querySnapshotSize > 0) {
            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                if (doc.data()) {
                    let {
                        fileName,
                        uploadDate,
                        from,
                        to,
                        message,
                        downloadURL
                    } = doc.data();

                    records.push({
                        id: doc.id,
                        fileName,
                        from: moment(from.toLocaleString()).utc().format("DD-MM-YYYY HH:MM:SS A"),
                        to: moment(to.toLocaleString()).utc().format("DD-MM-YYYY HH:MM:SS A"),
                        uploadDate: moment(new Date(uploadDate.toDate())).utc().format("DD-MM-YYYY HH:MM:SS A"),
                        message,
                        downloadURL
                    });
                }

                if (++i === querySnapshotSize) {
                    setData(records);
                }
            });
        } else {
            setData([]);
        }
    };

    const handleCreate = async (data) => {

        try {
            let docRef = collection(db, "Websites", "Admin", "Advertise");
            await addDoc(docRef, data);

            messageRef.current.value = "";
            fromRef.current.value = "";
            toRef.current.value = "";
            callToActionRef.current.value = "";
            visitUsRef.current.value = "";
            document.getElementById("fileUpload").value = "";

            setMessage("Advertise message is added !!");

            fetchAllAdvertise();
        } catch (err) {
            console.log(err);
            setError(err.message);
        }
    };

    const handleDelete = async ({ id }) => {

        if (window.confirm("Delete the item?")) {

            try {
                let docRef = doc(db, "Websites", "Admin", "Advertise", id);
                await deleteDoc(docRef);
                setData([]);
                fetchAllAdvertise();
            } catch (error) {
                setError(FilterText(error.message));
            }
        }
    };

    //CRUD PLANS END

    //STEP II
    useEffect(() => {
        setTimeout(() => {
            setIsPending(false);
            setProgress(0);
            setMessage("");
            setError("");
        }, 5000);
    }, [message, error]);

    //STEP I
    useEffect(() => {
        fetchAllAdvertise();
    }, [])

    return (
        <>
            <div className="flex w-screen">
                <Sidebar />
                <div className="body w-full flex-1 bg-gray-200 p-8 h-screen overflow-y-scroll">
                    <div>
                        <h1 className="bg-blue-300 p-2 text-2xl font-thin text-gray-600">
                            UPLOAD ADVERTISE
                        </h1>
                        <div className="w-full bg-gray-300">
                            <div className="flex justify-center space-x-32 ">
                                <div className="mt-4">
                                    <div className="flex flex-col space-y-2 w-[500px]">

                                        <textarea
                                            cols="30"
                                            rows="10"
                                            name="message"
                                            id="message"
                                            className="rounded-xl p-2"
                                            placeholder="Enter Message"
                                            ref={messageRef}
                                            required
                                        ></textarea>
                                        <input
                                            type="datetime-local"
                                            name="from"
                                            id="from"
                                            disabled={isPending}
                                            ref={fromRef}
                                            className="p-1"
                                        />
                                        <input
                                            type="datetime-local"
                                            name="to"
                                            id="to"
                                            disabled={isPending}
                                            ref={toRef}
                                            className="p-1"
                                        />
                                        <input
                                            type="number"
                                            name="callToAction"
                                            id="callToAction"
                                            disabled={isPending}
                                            ref={callToActionRef}
                                            className="p-1"
                                            placeholder='Call to Action'
                                        />
                                        <input
                                            type="text"
                                            name="visitUs"
                                            id="visitUs"
                                            disabled={isPending}
                                            ref={visitUsRef}
                                            className="p-1"
                                            placeholder='Visit Us'
                                        />
                                        <input
                                            id="fileUpload"
                                            type="file"
                                            name=""
                                            onChange={handleUpload}
                                            accept="image/*"
                                            disabled={isPending}
                                        />
                                        <div className="">
                                            <span className="mb-4 flex w-full items-center  justify-center space-x-2 rounded-xl bg-green-400 p-1">
                                                <Upload color="white" />
                                                {Math.floor(Progress)}%
                                            </span>
                                        </div>
                                    </div>
                                    <p className="md pt-4 font-bold text-red-600">{Error}</p>
                                </div>
                            </div>
                        </div>
                        <div className="my-4">
                            <h1 className="bg-blue-300 p-2 text-2xl font-thin text-gray-600">
                                ADVERTISE
                            </h1>
                            <div className="w-full bg-gray-300">
                                <DataTable columns={columns} data={Data} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Advertise
