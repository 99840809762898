import { Edit, Upload } from '@mui/icons-material';
import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, setDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Sidebar from '../../components/Sidebar'
import { db, storage } from '../../firebase';
import FilterText from '../../utils/FilterText';

import moment from 'moment/moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import DataTable from "../../components/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid";

function Broadcast() {

    const [data, setData] = useState([]);
    const [editing, setEditing] = useState(false);
    const [adding, setAdding] = useState(false);
    const [addFormData, setAddFormData] = useState({
        message: "",
        date: "",
        createdBy: "",
        platForm: ""
    });
    const [formData, setFormData] = useState({
        message: "",
        date: "",
        createdBy: "",
        platForm: ""
    });

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const columns = [

        {
            field: "date",
            headerName: "Date",
            minWidth: 130,
            align: "left",
            format: (value) => value.toLocaleString("en-IN"),
        },
        {
            field: "platForm",
            headerName: "Platform",
            minWidth: 130,
            align: "right",
        },
        { field: "message", headerName: "Message", minWidth: 170, align: "left" },
        { field: "downloadURL", headerName: "Image", minWidth: 170, align: "left" },
        { field: "createdBy", headerName: "Created By", minWidth: 170, align: "left" },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "right",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="Edit"
                    onClick={() => handleShowEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDelete(params.row)}
                />,
            ]
        },
    ];

    const handleAddChange = (e) => {
        setAddFormData((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const handleUpdateChange = (e) => {
        setFormData((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const handleCancelAdd = (e) => {
        e.preventDefault();
        setAdding(!adding);
    }

    const handleCancelUpdate = (e) => {
        e.preventDefault();
        setEditing(!editing);
    }

    const handleShowCreate = async (e) => {
        e.preventDefault();
        setAdding(!adding);
    };

    const handleShowEdit = (data) => {
        setEditing(!editing);
        setFormData(data);
    };

    const [isPending, setIsPending] = useState(false);
    const [progress, setProgress] = useState(false);


    //HANDLE CRUD UPLOADS
    const handleUpload = (e) => {
        e.preventDefault();

        const FileInfo = e.target.files[0];

        const FileRef = ref(
            storage,
            `Plugins/${new Date().getTime()}`
        );

        const metaData = {
            contentType: FileInfo.type,
        };

        const UplodPlugin = uploadBytesResumable(FileRef, FileInfo, metaData);

        UplodPlugin.on(
            "state_changed",
            (snapshot) => {
                setIsPending(true);
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
            },
            (error) => {
                setIsPending(false);
                setError(FilterText(error.message));
            },
            () => {
                getDownloadURL(UplodPlugin.snapshot.ref).then(async (downloadURL) => {
                    //ADD NEW BUSY PLUGIN LINKS

                    if (adding) {

                        setAddFormData(prev => {
                            return { ...prev, downloadURL: downloadURL, }
                        })
                    } else {
                        setFormData(prev => {
                            return { ...prev, downloadURL: downloadURL, }
                        })
                    }
                    setMessage("Uploaded successfully!!");
                });
            }
        );
    };

    const fetchAllBroadcast = async () => {
        const q = query(collection(db, "Websites", "Admin", "Broadcast"), orderBy("date", "asc"));
        const snapshots = await getDocs(q);

        let records = [];
        let querySnapshotSize = snapshots.docs.length;
        let i = 0;

        if (querySnapshotSize > 0) {
            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                if (doc.data()) {
                    let {
                        date,
                        message,
                        platForm,
                        createdBy,
                        downloadURL
                    } = doc.data();

                    records.push({
                        id: doc.id,
                        date: moment(date).utc().format("DD-MM-YYYY HH:MM:SS A"),
                        message,
                        platForm,
                        createdBy,
                        downloadURL
                    });
                }

                if (++i === querySnapshotSize) {
                    setData(records);
                }
            });
        } else {
            setData([]);
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();

        try {
            let docRef = collection(db, "Websites", "Admin", "Broadcast");
            await addDoc(docRef, addFormData);

            setAddFormData({
                message: "",
                date: "",
                createdBy: "",
                platForm: ""
            });
            setMessage("Broadcast message is added !!");
            document.getElementById("broadcastForm").reset();
            fetchAllBroadcast();
        } catch (err) {
            console.log(err);
            setError(err.message);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        try {
            let docRef = doc(db, "Websites", "Admin", "Broadcast", formData.id);
            await setDoc(docRef, formData);

            setEditing(!editing);
            setMessage("");
            setFormData({
                message: "",
                date: "",
                createdBy: "",
                platForm: ""
            });
            fetchAllBroadcast();
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = async ({ id }) => {
        try {
            let docRef = doc(db, "Websites", "Admin", "Broadcast", id);
            await deleteDoc(docRef);
            fetchAllBroadcast();
        } catch (error) {
            setError(FilterText(error.message));
        }
    };

    //CRUD PLANS END

    //STEP II
    useEffect(() => {
        setTimeout(() => {
            setIsPending(false);
            setProgress(0);
            setMessage("");
            setError("");
        }, 5000);
    }, [message, error]);

    //STEP I
    useEffect(() => {
        fetchAllBroadcast();
    }, [])

    return (
        <div className="flex">
            <Sidebar />
            <div className='w-full h-screen overflow-y-scroll'>
                <h1 className="mb-4 rounded bg-indigo-600 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl m-auto w-[50%] mt-12">
                    BROADCAST
                </h1>
                <div className='w-full p-8'>

                    {
                        !adding &&
                        !editing && (
                            <div className="flex justify-end">
                                <button className="bg-blue-400 text-white px-2 py-3 rounded-full" onClick={handleShowCreate}>
                                    ADD
                                </button>
                            </div>
                        )
                    }
                    <div className='flex flex-col items-center my-4 w-full mx-auto '>
                        {message && <p className='text-center text-white bg-green-400 px-4 py-1'>{message}</p>}
                        {error && <p className='text-center text-white bg-red-400 px-4 py-1'>{error}</p>}
                        {
                            adding && (<div className=" bg-white rounded-lg shadow-2xl p-6 w-[50%]">
                                <form onSubmit={handleCreate} id="broadcastForm">
                                    <div className='my-2'>
                                        <label className="block text-md font-medium text-gray-700 " htmlFor="message">Message</label>
                                        <textarea className='p-1 w-full border border-gray-300' name="message" id="message" cols="30" rows="5" value={addFormData.message} onChange={handleAddChange}></textarea>
                                    </div>
                                    <div className='my-2'>
                                        <label className="block text-md font-medium text-gray-700 " htmlFor="date">Date</label>
                                        <input type="datetime-local" id="date" name='date' value={addFormData.date} onChange={handleAddChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </div>
                                    <div className='my-2'>
                                        <label className="block text-md font-medium text-gray-700 " htmlFor="platForm">Platform</label>
                                        <select name="platForm" id="platForm" className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" value={addFormData.platForm} onChange={handleAddChange}>
                                            <option value="">Please Select</option>
                                            <option value="Desktop">Desktop</option>
                                            <option value="Tally">Tally</option>
                                        </select>
                                    </div>
                                    <div className='my-2'>
                                        <label className="block text-md font-medium text-gray-700 " htmlFor="createdBy">Created By</label>
                                        <input type="text" id="createdBy" name='createdBy' value={addFormData.createdBy} onChange={handleAddChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                    </div>
                                    <input
                                        type="file"
                                        name=""
                                        id=""
                                        disabled={isPending}
                                        onChange={handleUpload}
                                        required
                                    />
                                    <div className="mt-2">
                                        <span className="mb-4 flex w-full items-center  justify-center space-x-2 rounded-xl bg-green-400 p-1">
                                            <Upload color="white" />
                                            {Math.floor(progress)}%
                                        </span>
                                    </div>
                                    <div className='my-4 text-right'>
                                        <button type='submit' className='bg-blue-500 text-white mr-2 px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300'>Create</button>
                                        <button className='bg-red-500 text-white px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300' onClick={handleCancelAdd}>Cancel</button>
                                    </div>
                                </form>
                            </div>)
                        }
                        {editing && (<div className=" bg-white rounded-lg shadow-2xl p-6 w-[50%]">

                            <form onSubmit={handleUpdate}>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="message">Message</label>
                                    <textarea className='p-1 w-full border border-gray-300' name="message" id="message" cols="30" rows="5" value={formData.message} onChange={handleUpdateChange}></textarea>
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="date">Date</label>
                                    <input type="datetime-local" id="date" name='date' value={formData.date} onChange={handleUpdateChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="platForm">Platform</label>
                                    <select name="platForm" id="platForm" className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" value={formData.platForm} onChange={handleUpdateChange}>
                                        <option value="">Please Select</option>
                                        <option value="Desktop">Desktop</option>
                                        <option value="Tally">Tally</option>
                                    </select>
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="createdBy">Created By</label>
                                    <input type="text" id="createdBy" name='createdBy' value={formData.createdBy} onChange={handleUpdateChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                </div>
                                <div>
                                    <img src={formData.downloadURL} alt={formData.downloadURL} className='w-full h-[200px] object-cover' />
                                </div>
                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    disabled={isPending}
                                    onChange={handleUpload}
                                    required
                                />
                                <div className="mt-2">
                                    <span className="mb-4 flex w-full items-center  justify-center space-x-2 rounded-xl bg-green-400 p-1">
                                        <Upload color="white" />
                                        {Math.floor(progress)}%
                                    </span>
                                </div>
                                <div className='my-4 text-right'>
                                    <button type='submit' className='bg-blue-500 mr-2 text-white px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300'>Update</button>
                                    <button className='bg-red-500 text-white px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300' onClick={handleCancelUpdate}>Cancel</button>
                                </div>
                            </form>

                        </div>)
                        }
                    </div>
                    <div className='w-full'>
                        {
                            !adding &&
                            !editing &&
                            (
                                <DataTable columns={columns} data={data} />
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Broadcast
