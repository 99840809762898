import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import DataTable from "../../components/DataTable";
import {
  query,
  collection,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useRef } from "react";

export default function Plans() {
  const [data, setData] = useState([]);

  const originalDataRef = useRef([]);

  const columns = [
    { field: "listingOrder", headerName: "Listing Order", minWidth: 70, align: "left" },
    { field: "planCode", headerName: "Plan Code", minWidth: 170, align: "left" },
    { field: "plan", headerName: "Plan Name", minWidth: 170, align: "left" },
    {
      field: "price",
      headerName: "Price",
      minWidth: 130,
      align: "left",
    },
    {
      field: "additionalPlanPrice",
      headerName: "Additional User Price",
      minWidth: 150,
      align: "left",
    },
    {
      field: "productPermissions",
      headerName: "Product Permissions",
      minWidth: 200,
      align: "right",
    }
  ];

  //CRUD PLANS START
  const fetchAllPlans = async () => {
    const q = query(collection(db, "Websites", "Client", "Plans"), orderBy("listingOrder", "asc"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    if (querySnapshotSize > 0) {
      snapshots.forEach((doc) => {
        //FORMATE DATE OF JOINING START

        if (doc.data()) {
          let {
            benefits,
            productPermissions,
            listingOrder,
            plan,
            price,
            additionalPlanPrice,
            planCode
          } = doc.data();

          records.push({
            id: doc.id,
            productPermissions: productPermissions instanceof Array ? productPermissions?.join(",") : productPermissions,
            price,
            additionalPlanPrice,
            plan,
            benefits: benefits instanceof Array ? benefits.join(",") : benefits,
            listingOrder,
            planCode,
          });
        }

        if (++i === querySnapshotSize) {
          setData(records);
          originalDataRef.current = records;
        }
      });
    } else {
      setData([]);
    }

  };

  useEffect(() => {
    fetchAllPlans();
  }, []);

  return (
    <div className="flex w-screen">
      <Sidebar />
      {data && (
        <div className="body w-64 flex-1 bg-white p-8">
          <h1 className="mb-4 rounded bg-indigo-600 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
            PLANS
          </h1>
          <DataTable columns={columns} data={data} />
        </div>
      )}
    </div>
  );
}
