import axios from "axios";
import readXlsxFile from "read-excel-file";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "../../components/Sidebar";
import { Download } from "@mui/icons-material";
import Emojis from "../../utils/Emojis";
import { db } from "../../firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

const Message = () => {
    const userInfo = JSON.parse(localStorage.getItem("user"))
        ? JSON.parse(localStorage.getItem("user"))
        : { uid: "" };
    const [isProcessing, setIsProcessing] = useState(false);
    const [message, setMessage] = useState(null);
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);
    const [selectedFile, setSelectedFile] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isFileImportSelected, setIsFileImportSelected] = useState(false);
    const fileImportData = useRef([]);

    const [allUsers, setAllUsers] = useState(false);
    const [allOwnUsers, setOwnUsers] = useState(false);
    const [allResellers, setResellers] = useState(false);
    const [resellersUser, setResellersUser] = useState(false);

    const [sentMessage, setSentMessage] = useState(0);
    const [totalMessage, setTotalMessage] = useState(0);

    const cursorRef = useRef();

    const [isClicked, setIsClicked] = useState(false);

    const phoneRef = useRef();
    const messageRef = useRef();
    const fileRef = useRef();
    const phonesFile = useRef();

    const whatsAppIDRef = useRef("");

    const handleMessage = (e) => {
        setMessage((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const getSelectedEmoji = (selectedEmoji) => {
        // messageRef.current.value += selectedEmoji
        messageRef.current.value = messageRef.current.value.insert(cursorRef.current, ` ${selectedEmoji}`);
    }

    const handleExcelFile = async (e) => {
        const map = {
            Name: "Name",
            Phone: "Phone",
            Message: "Message",
        };

        let file = phonesFile.current.files[0];

        let phones = [];

        await readXlsxFile(file, { map }).then(({ rows }) => {
            rows.forEach((row) => {
                phones.push(row.Phone.toString().trim());
                fileImportData.current.push(row);
            });

            if (fileImportData.current.length > 0) {
                setIsFileImportSelected(true);
            }
        });

        phoneRef.current.value = phones.join(",");
        //SET Remain Msg to be send
        setTotalMessage(Number(phones.length));
    };

    const checkInvalidNumbers = (numbers) => {
        let invNumber = numbers.filter((number) => number.length !== 12);
        return invNumber;
    };

    const resetAllParameters = () => {
        setStatus(null);
        setError(null);
        setIsClicked(false);
        setSelectedFile(null);
        setIsSelected(false);

        setMessage(null);
        fileRef.current.value = null;
        phonesFile.current.value = null;
        messageRef.current.value = null;
        phoneRef.current.value = null;
        whatsAppIDRef.current.value = null;
    };

    useEffect(() => {
        console.log(sentMessage, totalMessage)
        console.log(status, error)

        if ((status !== null || error !== null) && sentMessage === totalMessage) {
            setTotalMessage(0);
            setSentMessage(0);
        }
        else if ((status !== null || error !== null)) {
            setTimeout(() => {
                resetAllParameters();
                setSentMessage(0);
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, status, sentMessage]);

    let i = 0;
    let phones = [];
    let apiURL = "";

    const formatedMessage = (msg, name) => {
        return msg.replace("[NAME]", name);
    };

    const sendFormatedMessage = (phone, fmsg) => {
        if (phone && phone !== "" && phone.toString().length <= 13) {
            var data = JSON.stringify({
                id: whatsAppIDRef.current.value.trim(),
                phone: phone.toString().trim(),
                message: fmsg,
            });

            var config = {
                method: "POST",
                url: apiURL,
                headers: {
                    "Content-type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    let msgResp = response.data.message;
                    let msgStatus = response.data.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                        i++;

                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {
                            let msg = formatedMessage(
                                message.message,
                                fileImportData.current[i].Name
                            );

                            sendFormatedMessage(fileImportData.current[i].Phone, msg);
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch(function (error) {
                    console.log(error.message);
                    setError(
                        "Your device is offline. Please check Internet in your mobile phone or reconnect your device"
                    );
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendFormatedFileMessage = (phone, fmsg) => {
        if (phone && phone !== "" && phone.toString().length <= 13) {
            const formData = new FormData();

            formData.append("id", userInfo.uid);
            formData.append("file", selectedFile);
            formData.append("message", fmsg);
            formData.append("phone", phone);

            fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {
                    let msgResp = result.message;
                    let msgStatus = result.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                        i++;

                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {
                            let msg = formatedMessage(
                                message.message,
                                fileImportData.current[i].Name
                            );

                            sendFormatedFileMessage(fileImportData.current[i].Phone, msg);
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setError(
                        "Your device is offline. Please check Internet in your mobile phone or reconnect your device"
                    );
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendFileMessageMulti = (phone) => {
        if (phone && phone !== "" && phone.toString().length <= 13) {
            const formData = new FormData();

            formData.append("id", userInfo.uid);
            formData.append("file", selectedFile);
            formData.append("message", message.message);
            formData.append("phone", phone);

            fetch(apiURL, {
                method: "POST",
                headers: {},
                body: formData,
            })
                .then((response) => response.json())
                .then((result) => {
                    let msgResp = result.message;
                    let msgStatus = result.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                        i++;
                        //Set Msg sent count
                        setSentMessage(i);
                        if (i < phones.length) {
                            sendFileMessageMulti(phones[i]);
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                    setError(
                        "Your device is offline. Please check Internet in your mobile phone or reconnect your device"
                    );
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendFileMessage = () => {
        apiURL = `https://messagesapi.co.in/chat/sendmessagefile/${whatsAppIDRef.current.value.trim()}`;

        phones = phoneRef.current.value.split(",");

        if (phones.length > 0) {
            sendFileMessageMulti(phones[i]);
        }
    };

    const sendMessageMulti = (phone) => {
        if (phone && phone !== "" && phone.toString().length <= 13) {
            var data = JSON.stringify({
                id: whatsAppIDRef.current.value.trim(),
                phone: phone,
                message: message.message,
            });

            var config = {
                method: "POST",
                url: apiURL,
                headers: {
                    "Content-type": "application/json",
                },
                data: data,
            };

            axios(config)
                .then(function (response) {
                    let msgResp = response.data.message;
                    let msgStatus = response.data.status;

                    if (msgStatus !== "error") {
                        setStatus(msgResp);
                        i++;
                        //Set Msg sent count
                        setSentMessage(i);

                        if (i < phones.length) {
                            sendMessageMulti(phones[i]);
                        } else {
                            i = 0;
                            phones = [];
                            apiURL = "";
                        }
                    } else {
                        setError(msgResp);
                    }
                })
                .catch(function (error) {
                    console.log(error.message);
                    setError(
                        "Your device is offline. Please check Internet in your mobile phone or reconnect your device"
                    );
                });
        } else {
            setError(`Invalid mobile number ${phone}`);
        }
    };

    const sendMessage = () => {
        setIsClicked(true);
        apiURL = "https://messagesapi.co.in/chat/sendmessage";
        phones = phoneRef.current.value.split(",");

        let invalidNumbers = checkInvalidNumbers(phones);

        if (invalidNumbers.length === 0) {
            if (phoneRef.current.value) {
                if (phoneRef.current.value.length !== 0) {
                    if (isSelected) {
                        apiURL = `https://messagesapi.co.in/chat/sendmessagefile/${whatsAppIDRef.current.value.trim()}`;

                        if (fileImportData.current.length > 0 && isFileImportSelected) {
                            let msg = formatedMessage(
                                message.message,
                                fileImportData.current[i].Name
                            );

                            sendFormatedFileMessage(fileImportData.current[i].Phone, msg);
                        } else {
                            sendFileMessage();
                        }
                    } else {
                        if (fileImportData.current.length > 0 && isFileImportSelected) {
                            let msg = formatedMessage(
                                message.message,
                                fileImportData.current[i].Name
                            );

                            sendFormatedMessage(fileImportData.current[i].Phone, msg);
                        } else {
                            sendMessageMulti(phones[i]);
                        }
                    }
                } else {
                    setError("Phone number is missing");
                }
            } else {
                setError("Phone is not valid");
            }
        } else {
            setError(`Check this number ${invalidNumbers.join(",")}`);
            invalidNumbers = [];
        }
    };

    const handleUserSelection = async (e) => {

        let mobiles = [];

        setAllUsers(e.target.checked);

        if (e.target.checked) {
            setIsProcessing(true);

            const q = query(collection(db, "Users"), orderBy("activationDate", "desc"));
            const snapshots = await getDocs(q);

            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                if (doc.data()) {
                    let {
                        mobile
                    } = doc.data();

                    if (mobile.length <= 12) {
                        mobile = `91${mobile}`
                    }

                    mobiles.push(mobile);

                }
            });

            const qch = query(collection(db, "Channel Partners"), orderBy("doj", "desc"));
            const snapshotsch = await getDocs(qch);

            snapshotsch.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                if (doc.data()) {
                    let {
                        mobile
                    } = doc.data();

                    if (mobile.length <= 12) {
                        mobile = `91${mobile}`
                    }

                    mobiles.push(mobile);

                }
            });

            phoneRef.current.value = mobiles.join(",");
            setIsProcessing(false);
        } else {
            phoneRef.current.value = [];
        }

        setTotalMessage(mobiles.length);
    }

    const handleOwnUserSelection = async (e) => {

        let mobiles = [];

        setOwnUsers(e.target.checked);

        if (e.target.checked) {
            setIsProcessing(true);

            const q = query(collection(db, "Users"), orderBy("activationDate", "desc"));
            const snapshots = await getDocs(q);

            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                let data = doc.data();

                if (
                    data &&
                    data.channelPartnerRefferalId === ""
                ) {
                    let {
                        mobile
                    } = data;

                    if (mobile.length <= 12) {
                        mobile = `91${mobile}`
                    }

                    mobiles.push(mobile);

                }
            });

            phoneRef.current.value = mobiles.join(",");
            setIsProcessing(false);
        } else {
            phoneRef.current.value = [];
        }

        setTotalMessage(mobiles.length);
    }

    const handleResellersSelection = async (e) => {

        let mobiles = [];

        setResellers(e.target.checked);

        if (e.target.checked) {
            setIsProcessing(true);

            const q = query(collection(db, "Channel Partners"), orderBy("doj", "desc"));
            const snapshots = await getDocs(q);

            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                let data = doc.data();

                if (
                    data
                ) {
                    let {
                        mobile
                    } = data;

                    if (mobile.length <= 12) {
                        mobile = `91${mobile}`
                    }

                    mobiles.push(mobile);

                }
            });

            phoneRef.current.value = mobiles.join(",");
            setIsProcessing(false);
        } else {
            phoneRef.current.value = [];
        }

        setTotalMessage(mobiles.length);
    }

    const handleResellersUserSelection = async (e) => {

        let mobiles = [];

        setResellersUser(e.target.checked);

        if (e.target.checked) {
            setIsProcessing(true);

            const q = query(collection(db, "Users"), orderBy("activationDate", "desc"));
            const snapshots = await getDocs(q);

            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                let data = doc.data();

                if (
                    data &&
                    data.channelPartnerRefferalId !== ""
                ) {
                    let {
                        mobile
                    } = data;

                    if (mobile.length <= 12) {
                        mobile = `91${mobile}`
                    }

                    mobiles.push(mobile);

                }
            });

            phoneRef.current.value = mobiles.join(",");
            setIsProcessing(false);
        } else {
            phoneRef.current.value = [];
        }

        setTotalMessage(mobiles.length);
    }

    // eslint-disable-next-line no-extend-native
    String.prototype.insert = function (index, string) {
        if (index > 0) {
            return this.substring(0, index) + string + this.substr(index);
        }

        return string + this;
    };

    const selFun = useCallback(e => {
        console.log('Caret at: ', e.target.selectionStart)
        cursorRef.current = e.target.selectionStart;
    }, []);

    useEffect(() => {
        document.getElementById('message').addEventListener('click', selFun, false);
        document.getElementById('message').addEventListener('keydown', selFun, false);
        document.getElementById('message').addEventListener('keyup', selFun, false);

        return () => {
            document.removeEventListener("click", selFun, false);
            document.removeEventListener("keydown", selFun, false);
            document.removeEventListener("keyup", selFun, false);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <div className="flex w-screen ">
            <Sidebar />
            <div className="body w-full flex-1 p-8 h-screen overflow-y-scroll">
                <div className="w-full">
                    <div className="w-10/12 rounded-md bg-slate-500  p-2">
                        <div className="head">
                            <h1 className="mb-4 text-center text-2xl font-bold tracking-wide text-white">
                                SEND MULTI MESSAGE
                            </h1>
                            <hr className="mb-2" />
                            {status !== "" && (
                                <p className="text-center text-xl tracking-normal text-green-500 ">
                                    {status}
                                </p>
                            )}
                            {error !== "" && (
                                <p className="text-center text-xl tracking-normal text-red-500">
                                    {error}
                                </p>
                            )}
                        </div>
                        <div className="body flex flex-col items-center justify-center">
                            <div
                                action=""
                                method="post"
                                className="flex w-10/12 flex-col space-y-4"
                            >
                                <div>
                                    <p className="inline-block text-xs font-bold uppercase leading-8 tracking-wider text-white">
                                        Download Sample Excel File
                                    </p>
                                    <a
                                        href="./assets/samples.xlsx"
                                        className="text-md ml-4 h-8 w-8 rounded-full bg-white font-semibold leading-8 text-blue-400"
                                    >
                                        <Download />
                                    </a>
                                </div>
                                <div className="flex justify-between text-white">
                                    <label
                                        htmlFor="phone"
                                        className="text-xl font-thin text-white"
                                    >
                                        MessageAPI UserID:
                                    </label>
                                    <input
                                        type="text"
                                        id="whatsAppIDRef"
                                        ref={whatsAppIDRef}
                                        className=" w-full rounded-md p-2 text-black"
                                    />
                                </div>
                                <div className="flex justify-between text-white">
                                    <label
                                        htmlFor="phone"
                                        className="text-xl font-thin text-white"
                                    >
                                        Import Phones From Excel:
                                    </label>
                                    <input
                                        type="file"
                                        id="input"
                                        ref={phonesFile}
                                        onChange={handleExcelFile}
                                        accept=".xlsx"
                                        disabled={isClicked}
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        className=" w-full rounded-md p-2"
                                        onChange={handleMessage}
                                        ref={phoneRef}
                                        disabled={isClicked}
                                    />
                                </div>
                                <div className="flex justify-start items-center space-x-2">

                                    <label
                                        htmlFor="users"
                                        className="text-xl font-thin text-white"
                                    >
                                        <span className="mx-2">All Users:</span>
                                        <input type="checkbox" name="users" id="users" onChange={handleUserSelection} className="mr-2" disabled={allOwnUsers || allResellers || resellersUser} />

                                    </label>
                                    <label
                                        htmlFor="users"
                                        className="text-xl font-thin text-white"
                                    >
                                        <span className="mx-2">Own Users:</span>
                                        <input type="checkbox" name="users" id="users" onChange={handleOwnUserSelection} className="mr-2" disabled={allUsers || allResellers || resellersUser} />

                                    </label>
                                    <label
                                        htmlFor="users"
                                        className="text-xl font-thin text-white"
                                    >
                                        <span className="mx-2">Resellers:</span>
                                        <input type="checkbox" name="users" id="users" onChange={handleResellersSelection} className="mr-2" disabled={allUsers || allOwnUsers || resellersUser} />

                                    </label>
                                    <label
                                        htmlFor="users"
                                        className="text-xl font-thin text-white"
                                    >
                                        <span className="mx-2">Reseller's User:</span>
                                        <input type="checkbox" name="users" id="users" onChange={handleResellersUserSelection} className="mr-2" disabled={allUsers || allOwnUsers || allResellers} />

                                    </label>
                                </div>
                                <div className="flex justify-start items-center space-x-2">
                                    {isProcessing && allUsers && (<span className="text-white font-sm">Please Wait...</span>)}
                                    {isProcessing && allOwnUsers && (<span className="text-white font-sm">Please Wait...</span>)}
                                    {isProcessing && allResellers && (<span className="text-white font-sm">Please Wait...</span>)}
                                    {isProcessing && resellersUser && (<span className="text-white font-sm">Please Wait...</span>)}
                                </div>
                                <label
                                    htmlFor="message"
                                    className="text-xl font-thin text-white"
                                >
                                    Message:{" "}
                                    <span className="text-md  text-gray-300">
                                        Total : {totalMessage}
                                    </span>{" "}
                                    |{" "}
                                    <span className="text-md  text-gray-300">
                                        Sent : {sentMessage}
                                    </span>
                                </label>
                                <textarea
                                    name="message"
                                    id="message"
                                    cols="30"
                                    rows="10"
                                    className="w-full rounded-md p-2"
                                    onChange={handleMessage}
                                    ref={messageRef}
                                    disabled={isClicked}
                                ></textarea>

                                <label htmlFor="file" className="text-xl font-thin text-white">
                                    Upload File:
                                </label>
                                <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={changeHandler}
                                    ref={fileRef}
                                    disabled={isClicked}
                                />

                                {isSelected && selectedFile ? (
                                    <div>
                                        <p>
                                            Filename: {selectedFile.name ? selectedFile.name : ""}
                                        </p>
                                        <p>
                                            Filetype: {selectedFile.type ? selectedFile.type : ""}
                                        </p>
                                        <p>
                                            Size in bytes:{" "}
                                            {selectedFile.size ? selectedFile.size : ""}
                                        </p>
                                        <p>
                                            lastModifiedDate:{" "}
                                            {selectedFile.lastModifiedDate
                                                ? selectedFile.lastModifiedDate.toLocaleDateString()
                                                : ""}
                                        </p>
                                    </div>
                                ) : (
                                    <p>Select a file to show details</p>
                                )}

                                <div className="p-2 text-right">
                                    <button
                                        className="rounded-md bg-yellow-400 py-2 px-12 text-white"
                                        onClick={sendMessage}
                                        disabled={isClicked || error === ""}
                                    >
                                        {isClicked ? "Sending..." : "Send"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Emojis getSelectedEmoji={(data) => getSelectedEmoji(data)} />
        </div>
    );
};

export default Message;
