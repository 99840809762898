import React, { useState, useEffect, useContext } from "react";

import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

import Sidebar from "../../components/Sidebar";
import profile from "./profile.png";

import { useLocation } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";

import moment from "moment";

import "../../index.css";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import FilterText from "../../utils/FilterText";


function Profile() {
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [userPlanProducts, setUserPlanProducts] = useState(null);
  const [channelPartnerProfile, setChannelPartnerProfile] = useState(null);
  const [loading, setLoading] = useState([]);
  const location = useLocation();
  const { user } = useContext(AuthContext);

  let uid = location?.state?.id ? location?.state?.id : user.uid;

  const columns = [
    { field: "productName", headerName: "Product Name", minWidth: 170, align: "left" },
    {
      field: "startingFrom",
      headerName: "Joining Date",
      minWidth: 130,
      align: "left",
    },
    {
      field: "endingTo",
      headerName: "Expiry Date",
      minWidth: 130,
      align: "left",
      format: (value) => {
        moment(new Date(Number(value)))
          .utc()
          .format("DD-MM-YYYY");
      },
    },

    { field: "noOfCompanies", headerName: "No Of Companies", minWidth: 170, align: "left" },
    { field: "noOfUsedCompanies", headerName: "No Of Used Companies", minWidth: 170, align: "left" },
    // { field: "noOfRemainingCompanies", headerName: "N.O.R.C", minWidth: 170, align: "left" },
    { field: "noOfBanks", headerName: "No Of Banks", minWidth: 170, align: "left" },
    { field: "noOfUsedBanks", headerName: "No Of Used Banks", minWidth: 170, align: "left" },
    // { field: "noOfRemainingBanks", headerName: "N.O.R.B", minWidth: 170, align: "left" },
    { field: "gstCredits", headerName: "GST Credits", minWidth: 170, align: "left" },
    { field: "gstUsedCredits", headerName: "GST Used Credits", minWidth: 170, align: "left" },
    // { field: "gstRemainingCredits", headerName: "G.R Credits", minWidth: 170, align: "left" },
    { field: "bpaCredits", headerName: "BPA Credits", minWidth: 170, align: "left" },
    { field: "bpaUsedCredits", headerName: "BPA Used Credits", minWidth: 220, align: "left" },
    // { field: "bpaRemainingCredits", headerName: "B.P.A.R Credits", minWidth: 170, align: "left" },
    { field: "braCredits", headerName: "BRDA Credits", minWidth: 230, align: "left" },
    { field: "braUsedCredits", headerName: "BRDA Used Credits", minWidth: 280, align: "left" },
    // { field: "braRemainingCredits", headerName: "B.R.A.R Credits", minWidth: 170, align: "left" },
    { field: "brmCredits", headerName: "BRDM Credits", minWidth: 280, align: "left" },
    { field: "brmUsedCredits", headerName: "BRDM Used Credits", minWidth: 280, align: "left" },
    // { field: "brmRemainingCredits", headerName: "B.R.M.R Credits", minWidth: 170, align: "left" },

  ];

  let fetchProfile = async () => {
    setLoading(true);

    if (uid) {

      let userProfileData = {};
      let docRef = doc(db, "Users", uid);

      userProfileData = await getDoc(docRef);

      if (userProfileData.exists()) {
        setUserProfile(userProfileData.data());

        let userPlanProducts = userProfileData.data().productBenefits.map(pp => {
          return {
            ...pp,
            startingFrom: moment(new Date(Number(pp.startingFrom)))
              .utc()
              .format("DD-MM-YYYY"),
            endingTo: moment(new Date(Number(pp.endingTo)))
              .utc()
              .format("DD-MM-YYYY"),
            status: pp.endingTo
          }
        })

        console.log("userPlanProducts", userPlanProducts);

        setUserPlanProducts(userPlanProducts);
        setLoading(false);
      }
    } else {
      setLoading(false);

    }
  }

  let fetchResellerProfile = async () => {

    if (userProfile.channelPartnerRefferalId !== "") {

      let channelPartnerProfile = {};
      let docRef = doc(db, "Channel Partners", userProfile.channelPartnerRefferalId);

      channelPartnerProfile = await getDoc(docRef);

      if (channelPartnerProfile.exists()) {
        setChannelPartnerProfile(channelPartnerProfile.data());
        // console.log("channelPartnerProfile.data()", channelPartnerProfile.data());
      }
    }
  }

  useEffect(() => {
    if (uid) fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  useEffect(() => {

    if (userProfile) fetchResellerProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setSuccessMessage("");
    }, 1000);
  }, [error, successMessage]);

  return (
    <div className="flex w-screen ">
      <Sidebar />
      {!loading && userProfile && (
        <div className="body w-64 flex-1 px-4 bg-white  overflow-y-scroll h-screen" >
          <h1 className="mb-8 text-left text-4xl font-thin uppercase leading-10 text-black">

          </h1>
          <div className="flex">
            <div className="flex-1">
              <div className="items-left mb-8 flex flex-col justify-center space-y-1">
                <img className="mb-8 w-24 rounded-full" src={profile} alt="" />
                <div>
                  <span className="text-xs  uppercase text-[#055592] font-serif">
                    Name:
                  </span>
                  <span className="ml-2 text-black">
                    {userProfile.personalName}
                  </span>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <h1 className="text-xs uppercase text-[#055592] font-serif">
                ADDRESS
              </h1>
              <p className="text-xs font-thin uppercase text-black">
                {userProfile.city}
              </p>
              <p className="text-xs font-thin uppercase text-black">
                {userProfile.mobile}
              </p>
              <p className="text-xs font-thin text-black">
                {userProfile.email}
              </p>
              <p className="text-xs font-thin uppercase text-black">
                {userProfile.pinCode}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <fieldset className=" border  p-3 " style={{ color: "#055592" }}>
              <p className="font-serif uppercase border-b-2 border-b-blue-200 mb-2">User Credential</p>
              <div className="mb-4">
                <p className="text-xs font-thin uppercase text-[#055592]">
                  User ID:
                  <span className="ml-2 normal-case text-black">
                    {userProfile.uid}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Username:
                  <span className="ml-2 lowercase text-black">
                    {userProfile.email}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Password:
                  <span className=" ml-2 normal-case text-black">
                    {userProfile.password}
                  </span>
                </p>
              </div>
            </fieldset>
            <fieldset className=" border  p-3 " style={{ color: "#055592" }}>
              <p className="font-serif uppercase border-b-2 border-b-blue-200 mb-2">Plan</p>

              <div className="mb-4">
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Current Plan:
                  <span className="ml-2 normal-case text-black">
                    {userProfile?.licenceInfo?.plan}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  D.O.J:
                  <span className="ml-2 lowercase text-black">
                    {moment(userProfile?.activationDate.toDate().toISOString()).format('DD/MM/YYYY')}

                  </span>
                </p>

                <p className="text-xs font-thin uppercase text-[#055592]">
                  Features:
                  <span className=" ml-2 normal-case text-black">
                    {userProfile?.licenceInfo?.benefits}
                  </span>
                </p>
              </div>
            </fieldset>
            <fieldset className=" border  p-3 " style={{ color: "#055592" }}>
              <p className="font-serif uppercase border-b-2 border-b-blue-200 mb-2">Business Details</p>
              <div className="mb-4">
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Business Name:
                  <span className="ml-2 normal-case text-black">
                    {userProfile?.businessName}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Business Category:
                  <span className="ml-2 lowercase text-black">
                    {userProfile?.businessCategory}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Email:
                  <span className=" ml-2 normal-case text-black">
                    {userProfile?.email}
                  </span>
                </p>
              </div>
            </fieldset>
            <fieldset className=" border  p-3 " style={{ color: "#055592" }}>
              <p className="font-serif uppercase border-b-2 border-b-blue-200 mb-2">Your Channel Partner</p>
              <div className="mb-4">
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Name:
                  <span className="ml-2 normal-case text-black">
                    {channelPartnerProfile?.personalName}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Mobile:
                  <span className="ml-2 lowercase text-black">
                    {channelPartnerProfile?.mobile}
                  </span>
                </p>
                <p className="text-xs font-thin uppercase text-[#055592]">
                  Email:
                  <span className=" ml-2 normal-case text-black">
                    {channelPartnerProfile?.email}
                  </span>
                </p>
              </div>
            </fieldset>
          </div>

          <p className="mb-4 text-center text-xs font-bold text-red-600">
            {error && error !== ""
              ? FilterText(error)
              : successMessage && successMessage !== ""
                ? FilterText(successMessage)
                : ""}
          </p>
          <div>
            <h1 className="text-2xl font-semibold text-[#055592] my-8 text-center">PLAN STATUS</h1>

            <Box
              sx={{
                height: 500,
                width: '100%',
                '& .active': {
                  // backgroundColor: '#007500',
                  color: '#007500',
                },
                '& .expire': {
                  // backgroundColor: '#e5020c',
                  color: '#e5020c',
                },
              }}
            >
              <DataGrid
                rows={userPlanProducts}
                columns={columns}
                getRowClassName={(params) => {

                  if (new Date().getTime() >= params.row.status) {
                    return 'expire';
                  }
                  else if (new Date().getTime() <= params.row.status) {
                    return 'active';
                  } else {
                    return '';
                  }
                }}
              />
            </Box>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
