import { Edit, Upload } from '@mui/icons-material';
import { addDoc, collection, deleteDoc, doc, getDocs, orderBy, query, setDoc } from 'firebase/firestore';
import React, { useState } from 'react'
import { useEffect } from 'react';
import Sidebar from '../../components/Sidebar'
import { db, storage } from '../../firebase';
import FilterText from '../../utils/FilterText';
import moment from 'moment/moment';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import DataTable from "../../components/DataTable";
import { GridActionsCellItem } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

function Testimonials() {
    const [data, setData] = useState([]);
    const [editing, setEditing] = useState(false);
    const [adding, setAdding] = useState(false);
    const [addFormData, setAddFormData] = useState({
        address: "",
        date: "",
        downloadURL: "",
        name: ""
    });
    const [formData, setFormData] = useState({
        address: "",
        date: "",
        downloadURL: "",
        name: ""
    });

    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    const [isPending, setIsPending] = useState(false);
    const [progress, setProgress] = useState(false);

    const columns = [

        {
            field: "date",
            headerName: "Date",
            minWidth: 200,
            align: "left",
            valueFormatter: params =>
                moment(params?.value).format("DD-MM-YYYY hh:mm A"),
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 130,
            align: "right",
        },
        {
            field: "profession",
            headerName: "Profession",
            minWidth: 130,
            align: "right",
        },
        {
            field: "message",
            headerName: "Message",
            minWidth: 130,
            align: "right",
        },
        {
            field: "rating",
            headerName: "Rating",
            minWidth: 130,
            align: "right",
        },
        { field: "address", headerName: "Address", minWidth: 170, align: "left" },
        { field: "downloadURL", headerName: "Image", minWidth: 170, align: "left" },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "right",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="Edit"
                    onClick={() => handleShowEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDelete(params.row)}
                />,
            ]
        },
    ];

    const handleAddChange = (e) => {
        setAddFormData((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const handleUpdateChange = (e) => {
        setFormData((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const handleCancelAdd = (e) => {
        e.preventDefault();
        setAdding(!adding);
    }

    const handleCancelUpdate = (e) => {
        e.preventDefault();
        setEditing(!editing);
    }

    const handleShowEdit = (data) => {
        setEditing(!editing);
        setFormData({ ...data, date: moment(data.date).utc().format("YYYY-MM-DD hh:mm:ss") });

        console.log({ ...data, date: moment(data.date).utc().format("YYYY-MM-DD hh:mm:ss") })

    };

    //HANDLE CRUD UPLOADS
    const handleUpload = (e) => {
        e.preventDefault();

        const FileInfo = e.target.files[0];

        const FileRef = ref(
            storage,
            `Plugins/${new Date().getTime()}`
        );

        const metaData = {
            contentType: FileInfo.type,
        };

        const UplodPlugin = uploadBytesResumable(FileRef, FileInfo, metaData);

        UplodPlugin.on(
            "state_changed",
            (snapshot) => {
                setIsPending(true);
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(progress);
            },
            (error) => {
                setIsPending(false);
                setError(FilterText(error.message));
            },
            () => {
                getDownloadURL(UplodPlugin.snapshot.ref).then(async (downloadURL) => {
                    //ADD NEW BUSY PLUGIN LINKS

                    if (adding) {
                        setAddFormData(prev => {
                            return { ...prev, downloadURL: downloadURL, }
                        })
                    } else {
                        setFormData(prev => {
                            return { ...prev, downloadURL: downloadURL, }
                        })
                    }
                    setMessage("Uploaded successfully!!");
                });
            }
        );
    };

    const fetchAllTestimonials = async () => {
        const q = query(collection(db, "Websites", "Admin", "Testimonials"), orderBy("date", "asc"));
        const snapshots = await getDocs(q);

        let records = [];
        let querySnapshotSize = snapshots.docs.length;
        let i = 0;

        if (querySnapshotSize > 0) {
            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                if (doc.data()) {
                    let {
                        date,
                        address,
                        name,
                        message,
                        profession,
                        rating,
                        downloadURL
                    } = doc.data();

                    records.push({
                        id: doc.id,
                        date,
                        address,
                        name,
                        message,
                        profession,
                        rating,
                        downloadURL
                    });
                }

                if (++i === querySnapshotSize) {
                    setData(records);
                }
            });
        } else {
            setData([]);
        }
    };

    const handleCreate = async (e) => {
        e.preventDefault();

        try {
            let docRef = collection(db, "Websites", "Admin", "Testimonials");
            await addDoc(docRef, addFormData);

            setAddFormData({
                address: "",
                date: "",
                downloadURL: "",
                name: ""
            });
            setMessage("Testimonials message is added !!");
            document.getElementById("broadcastForm").reset();
            fetchAllTestimonials();
        } catch (err) {
            console.log(err);
            setError(err.message);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();

        try {
            let docRef = doc(db, "Websites", "Admin", "Testimonials", formData.id);
            await setDoc(docRef, { ...formData, date: new Date(formData.date).toISOString() });

            setEditing(!editing);
            setMessage("");
            setFormData({
                address: "",
                date: "",
                downloadURL: "",
                name: "",
                message: "",
                profession: "",
                rating: ""
            });
            fetchAllTestimonials();
        } catch (err) {
            console.log(err);
        }
    };

    const handleDelete = async ({ id }) => {
        try {
            let docRef = doc(db, "Websites", "Admin", "Testimonials", id);
            await deleteDoc(docRef);
            fetchAllTestimonials();
        } catch (error) {
            setError(FilterText(error.message));
        }
    };

    //STEP II
    useEffect(() => {
        setTimeout(() => {
            setIsPending(false);
            setProgress(0);
            setMessage("");
            setError("");
        }, 5000);
    }, [message, error]);

    //STEP I
    useEffect(() => {
        fetchAllTestimonials();
    }, [])

    return (
        <div className="flex">
            <Sidebar />
            <div className='w-full p-8 h-screen overflow-y-scroll'>
                <h1 className="mb-4 rounded bg-indigo-600 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl m-auto w-[50%]">
                    TESTIMONIALS
                </h1>
                {
                    !adding &&
                    !editing && (
                        <div className="flex justify-end">
                            <button className="bg-blue-400 text-white px-2 py-3 rounded-full" onClick={() => setAdding(true)}>
                                ADD
                            </button>
                        </div>
                    )
                }
                <div className='flex flex-col items-center my-4 w-full mx-auto '>
                    {message && <p className='text-center text-white bg-green-400 px-4 py-1'>{message}</p>}
                    {error && <p className='text-center text-white bg-red-400 px-4 py-1'>{error}</p>}
                    {
                        adding && (<div className=" bg-white rounded-lg shadow-2xl p-6 w-[50%]">
                            <form onSubmit={handleCreate} id="broadcastForm">
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="date">Date</label>
                                    <input type="datetime-local" id="date" name='date' value={addFormData.date} onChange={handleAddChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="name">Name</label>
                                    <input type="text" name="name" id="name" className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" value={addFormData.name} onChange={handleAddChange} />
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="message">Message</label>
                                    <textarea className='p-1 w-full border border-gray-300' name="message" id="message" cols="30" rows="5" value={addFormData.message} onChange={handleAddChange}></textarea>
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="address">Address</label>
                                    <textarea className='p-1 w-full border border-gray-300' name="address" id="address" cols="30" rows="5" value={addFormData.address} onChange={handleAddChange}></textarea>
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="rating">Rating (out of 5)</label>
                                    <input type="number" name="rating" id="rating" className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" value={addFormData.rating} onChange={handleAddChange} />
                                </div>
                                <div className='my-2'>
                                    <label className="block text-md font-medium text-gray-700 " htmlFor="profession">Profession</label>
                                    <select
                                        className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        value={addFormData.profession} onChange={handleAddChange}
                                        type="text"
                                        name="profession"
                                        id="profession" >
                                        <option value="">Select Profession</option>
                                        <option value="Chartered Accountant">Chartered Accountant</option>
                                        <option value="Tally Partner">Tally Partner</option>
                                        <option value="Tally User">Tally User</option>
                                    </select>
                                </div>

                                <input
                                    type="file"
                                    name=""
                                    id=""
                                    disabled={isPending}
                                    onChange={handleUpload}
                                    required
                                />
                                <div className="mt-2">
                                    <span className="mb-4 flex w-full items-center  justify-center space-x-2 rounded-xl bg-green-400 p-1">
                                        <Upload color="white" />
                                        {Math.floor(progress)}%
                                    </span>
                                </div>
                                <div className='my-4 text-right'>
                                    <button type='submit' className='bg-blue-500 text-white mr-2 px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300' disabled={isPending}>Create</button>
                                    <button className='bg-red-500 text-white px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300' onClick={handleCancelAdd} disabled={isPending}>Cancel</button>
                                </div>
                            </form>
                        </div>)
                    }
                    {editing && (<div className=" bg-white rounded-lg shadow-2xl p-6 w-[50%]">

                        <form onSubmit={handleUpdate} id="broadcastForm">
                            <div className='my-2'>
                                <label className="block text-md font-medium text-gray-700 " htmlFor="date">Date</label>
                                <input type="datetime-local" id="date" name='date' value={formData.date} onChange={handleUpdateChange} className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                            </div>
                            <div className='my-2'>
                                <label className="block text-md font-medium text-gray-700 " htmlFor="name">Name</label>
                                <input type="text" name="name" id="name" className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" value={formData.name} onChange={handleUpdateChange} />
                            </div>
                            <div className='my-2'>
                                <label className="block text-md font-medium text-gray-700 " htmlFor="message">Message</label>
                                <textarea className='p-1 w-full border border-gray-300' name="message" id="message" cols="30" rows="5" value={formData.message} onChange={handleUpdateChange}></textarea>
                            </div>
                            <div className='my-2'>
                                <label className="block text-md font-medium text-gray-700 " htmlFor="address">Address</label>
                                <textarea className='p-1 w-full border border-gray-300' name="address" id="address" cols="30" rows="5" value={formData.address} onChange={handleUpdateChange}></textarea>
                            </div>
                            <div className='my-2'>
                                <label className="block text-md font-medium text-gray-700 " htmlFor="rating">Rating (out of 5)</label>
                                <input type="number" name="rating" id="rating" className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" value={formData.rating} onChange={handleUpdateChange} />
                            </div>
                            <div className='my-2'>
                                <label className="block text-md font-medium text-gray-700 " htmlFor="profession">Profession</label>
                                <select
                                    className=" border-1 mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                    value={formData.profession} onChange={handleUpdateChange}
                                    type="text"
                                    name="profession"
                                    id="profession" >
                                    <option value="">Select Profession</option>
                                    <option value="Chartered Accountant">Chartered Accountant</option>
                                    <option value="Tally Partner">Tally Partner</option>
                                    <option value="Tally User">Tally User</option>
                                </select>
                            </div>

                            <input
                                type="file"
                                name=""
                                id=""
                                disabled={isPending}
                                onChange={handleUpload}
                            />
                            <div className="mt-2">
                                <span className="mb-4 flex w-full items-center  justify-center space-x-2 rounded-xl bg-green-400 p-1">
                                    <Upload color="white" />
                                    {Math.floor(progress)}%
                                </span>
                            </div>
                            <div className='my-4 text-right'>
                                <button type='submit' className='bg-blue-500 text-white mr-2 px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300' disabled={isPending}>Update</button>
                                <button className='bg-red-500 text-white px-4 py-1 rounded-full cursor-pointer hover:bg-blue-300' onClick={handleCancelUpdate} disabled={isPending}>Cancel</button>
                            </div>
                        </form>

                    </div>)
                    }
                </div>
                <div className='w-full'>
                    {
                        !adding &&
                        !editing &&
                        (
                            <DataTable columns={columns} data={data} />
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Testimonials
