import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import DataTable from "../../components/DataTable";
import {
  query,
  collection,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useRef } from "react";

export default function Products() {
  const [data, setData] = useState([]);

  const originalDataRef = useRef([]);

  const columns = [
    { field: "productName", headerName: "Product Name", minWidth: 70, align: "left" },
    { field: "productValidity", headerName: "Validity", minWidth: 70, align: "left" },
    { field: "accessPlatform", headerName: "Access Platform", minWidth: 170, align: "left" },
    {
      field: "description",
      headerName: "Description",
      minWidth: 130,
      align: "left",
    },
    {
      field: "gstCredits",
      headerName: "GST Credits",
      minWidth: 90,
      align: "right",
    },
    {
      field: "bracredits",
      headerName: "BRA Credits",
      minWidth: 90,
      align: "right",
    },
    {
      field: "brmCredits",
      headerName: "BRM Credits",
      minWidth: 90,
      align: "right",
    },
    {
      field: "bpaCredits",
      headerName: "BRP Credits",
      minWidth: 90,
      align: "right",
    },
    { field: "noOfCompanies", headerName: "N.O.C", minWidth: 70, align: "left" },
    {
      field: "noOfBanks",
      headerName: "N.O.B",
      minWidth: 90,
      align: "right",
    },
    {
      field: "noOfAOSCompanies",
      headerName: "A.O.S",
      minWidth: 90,
      align: "right",
    },
  ];

  //CRUD PRODUCTS START
  const fetchAllProducts = async () => {
    const q = query(collection(db, "Websites", "Client", "Products"), orderBy("productName", "asc"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    if (querySnapshotSize > 0) {
      snapshots.forEach((doc) => {
        //FORMATE DATE OF JOINING START

        if (doc.data()) {
          let {
            productName,
            productValidity,
            accessPlatform,
            description,
            gstCredits,
            gstUsedCredits,
            gstRemainingCredits,
            noOfCompanies,
            noOfUsedCompanies,
            noOfRemainingCompanies,
            braCredits,
            braUsedCredits,
            braRemainingCredits,
            brmCredits,
            brmUsedCredits,
            brmRemainingCredits,
            bpaCredits,
            bpaUsedCredits,
            bpaRemainingCredits,
            noOfBanks,
            noOfUsedBanks,
            noOfRemainingBanks,
            noOfAOSCompanies,
            noOfUsedAOSCompanies,
            noOfRemainingAOSCompanies,
          } = doc.data();

          records.push({
            id: doc.id,
            productName,
            productValidity,
            accessPlatform: accessPlatform instanceof Array ? accessPlatform?.join(",") : accessPlatform,
            description,
            gstCredits,
            gstUsedCredits,
            gstRemainingCredits,
            noOfCompanies,
            noOfUsedCompanies,
            noOfRemainingCompanies,
            braCredits,
            braUsedCredits,
            braRemainingCredits,
            brmCredits,
            brmUsedCredits,
            brmRemainingCredits,
            bpaCredits,
            bpaUsedCredits,
            bpaRemainingCredits,
            noOfBanks,
            noOfUsedBanks,
            noOfRemainingBanks,
            noOfAOSCompanies,
            noOfUsedAOSCompanies,
            noOfRemainingAOSCompanies,
          });
        }

        if (++i === querySnapshotSize) {
          setData(records);
          originalDataRef.current = records;
        }
      });
    } else {
      setData([]);
    }

  };

  //CRUD PRODUCTS END

  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <div className="flex w-screen">
      <Sidebar />
      {data && (
        <div className="body w-64 flex-1 bg-white p-8">
          <h1 className="mb-4 rounded bg-indigo-600 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
            PRODUCTS
          </h1>
          <DataTable columns={columns} data={data} />
        </div>
      )}
    </div>
  );
}
