import React, { useState, useEffect } from "react";

import DataTable from "../../components/DataTable";
import Sidebar from "../../components/Sidebar";
import {
    query,
    doc,
    setDoc,
    addDoc,
    deleteDoc,
    collection,
    getDocs,
    orderBy,
    updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Edit, Visibility } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import FilterText from "../../utils/FilterText";
import { useRef } from "react";

import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import Alerts from "../../components/Alerts";

export default function ContactUs() {
    const [data, setData] = useState(null);
    const [editing, setEditing] = useState(false);

    const [formData, setFormData] = useState({
        "id": "",
        "businessName": "",
        "city": "",
        "email": "",
        "mobile": "",
        "erpType": "",
        "personalName": "",
        "pincode": "",
        "profile": "",
        "date": "",
        "status": "",
        "executive": "",
        "followupDate": "",
        "followupMessage": ""

    });

    const [message, setMessage] = useState("");
    const [processing, setProcessing] = useState("");
    const [error, setError] = useState("");
    const originalDataRef = useRef([]);
    const navigate = useNavigate();

    const columns = [
        {
            field: "date",
            headerName: "Date",
            minWidth: 200,
            align: "left",
            sortable: false,
        },
        { field: "personalName", headerName: "Personal Name", minWidth: 170, align: "left" },
        { field: "businessName", headerName: "Business Name", minWidth: 170, align: "left" },
        {
            field: "city",
            headerName: "City",
            minWidth: 130,
            align: "left",
        },
        {
            field: "email",
            headerName: "Email",
            minWidth: 130,
            align: "right",
        },
        {
            field: "mobile",
            headerName: "Mobile",
            minWidth: 130,
            align: "center",
        },
        {
            field: "erpType",
            headerName: "ERP",
            minWidth: 130,
            align: "center",
        },
        {
            field: "profile",
            headerName: "Profile",
            minWidth: 130,
            align: "center",
        },
        {
            field: "followupDate",
            headerName: "Follow Up Date",
            minWidth: 200,
            align: "center",
        },
        {
            field: "attained",
            headerName: "Attained Date",
            minWidth: 200,
            align: "center",
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 130,
            align: "center",
            format: (value) => value.toLocaleString("en-IN"),
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "right",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="Edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<Visibility />}
                    label="View"
                    onClick={() => navigate(
                        "/contactUsFollowUpDetails", { state: { ...params.row } }
                    )}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDelete(params.row)}
                />,
            ]
        },
    ];

    const handleEdit = (data) => {
        setEditing(!editing);
        setFormData(data);
    };

    const handleDelete = async ({ id }) => {
        try {
            let docRef = doc(db, "Websites", "Client", "ContactUs", id);
            await deleteDoc(docRef);
            fetchAllContactUs();
        } catch (error) {
            setMessage(FilterText(error.message))
        }
    };

    const handleChange = (e) => {
        setFormData((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setProcessing(true);

        let {
            businessName,
            city,
            email,
            mobile,
            erpType,
            personalName,
            pincode,
            profile,
            date,
            executive,
            status,
            followupDate = "",
            attained = "",
            followupMessage = "",
        } = formData;

        try {
            let docRef = doc(db, "Websites", "Client", "ContactUs", formData.id);
            let colRef = collection(db, "Websites", "Client", "ContactUs", formData.id, "FollowUp");

            await updateDoc(docRef, {
                businessName,
                city,
                email,
                mobile,
                erpType,
                personalName,
                pincode,
                profile,
                status,
                date,
                followupDate,
                attained,
            });

            if (
                followupDate !== "" &&
                followupMessage !== ""
            ) {
                await addDoc(colRef, {
                    followupDate,
                    followupMessage,
                    status,
                    executive,
                })
            }


            setEditing(!editing);
            setMessage("Record update successfully!");
            setProcessing(false);
        } catch (err) {
            console.log(err);
        }
    };

    const changeDate = (date) => {
        let dateArray = [];
        let m = "";
        let d = "";
        let y = "";

        if (date.includes("-")) {

            dateArray = date.split("-");
            m = dateArray[1];
            d = dateArray[0];
            y = dateArray[2];
        } else if (date.includes("/")) {

            dateArray = date.split("/");
            m = dateArray[1];
            d = dateArray[0];
            y = dateArray[2];
        }

        return y + "/" + m + "/" + d
    }

    //FETCH ALL CONTACT US
    const fetchAllContactUs = async () => {
        const q = query(collection(db, "Websites", "Client", "ContactUs"), orderBy("date", "desc"));
        const snapshots = await getDocs(q);

        let records = [];
        let querySnapshotSize = snapshots.docs.length;
        let i = 0;

        snapshots.forEach((doc) => {
            //FORMATE DATE OF JOINING START

            if (doc.data()) {
                let {
                    businessName,
                    city,
                    email,
                    mobile,
                    erpType,
                    personalName,
                    pincode,
                    profile,
                    status = "",
                    date,
                    followupDate,
                    attained
                } = doc.data()

                records.push({
                    id: doc.id,
                    businessName,
                    city,
                    email,
                    mobile,
                    erpType,
                    personalName,
                    pincode,
                    profile,
                    status,
                    followupDate,
                    attained,
                    date: changeDate(date)
                });

            }

            if (++i === querySnapshotSize) {

                originalDataRef.current = records.sort(function (a, b) {
                    return new Date(b.date) - new Date(a.date);
                });

                originalDataRef.current = originalDataRef.current.map(d => {
                    return {
                        ...d, date: moment(d.date).utc()
                            .tz("Asia/Kolkata")
                            .format("DD-MM-YYYY")
                    }
                })

                originalDataRef.current = originalDataRef.current.map(d => {
                    if (d.followupDate) {
                        return {
                            ...d, followupDate: moment(d.followupDate).utc()
                                .tz("Asia/Kolkata")
                                .format("DD-MM-YYYY hh:mm:ss A")
                        }
                    } else {
                        return d;
                    }
                })

                originalDataRef.current = originalDataRef.current.map(d => {
                    if (d.attained) {
                        return {
                            ...d, attained: moment(d.attained).utc()
                                .tz("Asia/Kolkata")
                                .format("DD-MM-YYYY hh:mm:ss A")
                        }
                    } else {
                        return d;
                    }
                })

                setData(originalDataRef.current);
            }
        });
    };

    useEffect(() => {
        setTimeout(() => {
            setMessage("");
            setError("");
        }, [5000])
    }, [error, message])

    useEffect(() => {
        fetchAllContactUs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editing]);

    return (
        <div className="flex w-screen">
            <Sidebar />

            {editing && (
                <div className="body w-64 flex-1 bg-gray-200 p-8">
                    <p className="mb-4 w-full text-center font-sans text-xl text-red-500">
                        {FilterText(message)}
                    </p>
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Contact Us Information
                                </h3>
                                <p className="mt-1 text-sm text-gray-600">
                                    Update Contact Us
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 md:col-span-2 md:mt-0">

                            <form onSubmit={handleUpdate}>
                                <div className="overflow-hidden shadow sm:rounded-md">
                                    <div className="bg-white px-4 py-5 sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <input
                                                    type="hidden"
                                                    name="id"
                                                    id="id"
                                                    value={formData.id}
                                                    onChange={handleChange}
                                                    autoComplete="id"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="businessName"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Business Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="businessName"
                                                    id="businessName"
                                                    value={formData.businessName}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="personalName"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Personal Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="personalName"
                                                    id="personalName"
                                                    value={formData.personalName}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="email"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="mobile"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Mobile
                                                </label>
                                                <input
                                                    type="number"
                                                    name="mobile"
                                                    id="mobile"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="city"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    City
                                                </label>
                                                <input
                                                    type="text"
                                                    name="city"
                                                    id="city"
                                                    value={formData.city}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="pincode"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Pin Code
                                                </label>
                                                <input
                                                    type="number"
                                                    name="pincode"
                                                    id="pincode"
                                                    value={formData.pincode}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="erpType"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    ERP Type
                                                </label>
                                                <select
                                                    name="erpType"
                                                    id="erpType"
                                                    onChange={handleChange}
                                                    value={formData ? formData.erpType : ""}
                                                    className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option value="">Please Select</option>
                                                    <option value="Tally">Tally</option>
                                                    <option value="Busy">Busy</option>
                                                    <option value="Other">Other</option>

                                                </select>
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="profile"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Profile
                                                </label>
                                                <select
                                                    name="profile"
                                                    id="profile"
                                                    onChange={handleChange}
                                                    value={formData ? formData.profile : ""}
                                                    className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option value="">Please Select</option>
                                                    <option value="Business Owner">Business Owner</option>
                                                    <option value="Tally Partner">Tally Partner</option>
                                                    <option value="Chartered Accountant">Chartered Accountant</option>
                                                    <option value="Other Software Seller">Other Software Seller</option>

                                                </select>
                                            </div>
                                            <hr className="col-span-6" />
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="executive"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Executive (Full Name)
                                                </label>
                                                <input
                                                    type="text"
                                                    name="executive"
                                                    id="executive"
                                                    value={formData.executive}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="followupDate"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Follow Up Date
                                                </label>
                                                <input
                                                    type="datetime-local"
                                                    name="followupDate"
                                                    id="followupDate"
                                                    value={formData.followupDate}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="attained"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Attained
                                                </label>
                                                <input
                                                    type="datetime-local"
                                                    name="attained"
                                                    id="attained"
                                                    value={formData.attained}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="followupMessage"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Follow Up Message
                                                </label>
                                                <textarea
                                                    cols="30"
                                                    rows="4"
                                                    type="text"
                                                    name="followupMessage"
                                                    id="followupMessage"
                                                    value={formData.followupMessage}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                >
                                                </textarea>
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="status"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Status
                                                </label>
                                                <select
                                                    name="status"
                                                    id="status"
                                                    onChange={handleChange}
                                                    value={formData.status}
                                                    className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option value="">Please Select</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Follow Up">Follow Up</option>
                                                    <option value="Not Interested">Not Interested</option>
                                                    <option value="Done">Done</option>

                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                        {!processing ? (
                                            <button
                                                type="submit"
                                                value="Submit"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Save
                                            </button>
                                        ) : (
                                            <button
                                                disabled={processing}
                                                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Wait...
                                            </button>
                                        )}
                                        <button
                                            type="submit"
                                            value="Submit"
                                            onClick={() => setEditing(false)}
                                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}

            {

                !editing &&
                (
                    <div className="body w-64 flex-1 bg-white p-8">
                        <h1 className="mb-4 rounded bg-indigo-600 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
                            CONTACT US
                        </h1>

                        {data && (
                            <DataTable columns={columns} data={data} />
                        )}
                        {!data && <p>No record found :(</p>}
                    </div>
                )
            }

            <Alerts alertType="Error">{error}</Alerts>
        </div>
    );
}
