import { Edit, Upload } from "@mui/icons-material";
import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL, getStorage, deleteObject } from "firebase/storage";
import {
  addDoc,
  doc,
  collection,
  getDocs,
  query,
  deleteDoc,
  orderBy,
} from "firebase/firestore";

import Alerts from "../../components/Alerts"

import FilterText from "../../utils/FilterText";
import { useCallback } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "../../components/DataTable";
import axios from "axios";

function Plugin() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [Progress, setProgress] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const verRef = useRef("");
  const platformRef = useRef("");
  const bitRef = useRef("");
  const [data, setData] = useState([]);
  const [editing, setEditing] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const emailsRef = useRef();
  const [editData, setEditData] = useState("");

  const handlePluginEdit = (data) => {
    setEditing(true);
    setEditData(data);
  }

  const columns = [
    { field: "fileName", headerName: "File Name", width: 400, align: "left" },
    {
      field: "uploadDate",
      headerName: "Uploaded Date",
      width: 300,
      align: "center",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      field: "version",
      headerName: "Version",
      minWidth: 130,
      align: "left",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      headerName: "Actions",
      minWidth: 170,
      align: "center",
      getActions: (params) => [

        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handlePluginEdit(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handlePluginDelete(params.row)}
        />,
      ]
    },
  ];

  const fetchPlugins = useCallback(async () => {
    let results = [];

    if (results.length === 0) {
      let q = query(collection(db, "Websites", "Client", "Plugins"));
      const snapshot = await getDocs(q);

      snapshot.forEach((snapData) => {
        results.push({ ...snapData.data(), id: snapData.id });
      });

      setData(results);
    }

  }, []);

  //HANDLE CRUD UPLOADS
  const handleUpload = (e) => {
    e.preventDefault();

    if (
      verRef.current.value !== "" &&
      platformRef.current.value !== "" &&
      bitRef.current.value !== ""
    ) {
      const FileInfo = e.target.files[0];

      const FileRef = ref(
        storage,
        `Plugins/${verRef.current?.value + FileInfo.name}`
      );

      const metaData = {
        contentType: FileInfo.type,
      };

      const UplodPlugin = uploadBytesResumable(FileRef, FileInfo, metaData);

      UplodPlugin.on(
        "state_changed",
        (snapshot) => {
          setIsPending(true);
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
        },
        (error) => {
          setIsPending(false);
          setError(FilterText(error.message));
        },
        () => {
          getDownloadURL(UplodPlugin.snapshot.ref).then(async (downloadURL) => {
            //ADD NEW BUSY PLUGIN LINKS
            let docRef = collection(db, "Websites", "Client", "Plugins");

            await addDoc(docRef, {
              uploadDate: new Date().toLocaleDateString("en-IN", {
                timeZone: "Asia/Kolkata",
              }),
              fileName: FileInfo.name,
              version: verRef.current?.value,
              platform: platformRef.current?.value,
              bit: bitRef.current?.value,
              downloadURL: downloadURL,
            });
            setMessage("Uploaded successfully!!");
            fetchPlugins();
            document.getElementById("uploadForm").reset();
          });
        }
      );
    } else if (verRef.current.value === "") {
      setError("Version is missing (:");
    } else if (platformRef.current.value === "") {
      setError("Platform is missing (:");
    } else if (bitRef.current.value === "") {
      setError("Bit is missing (:");
    } else {
      setError("Something is wrong!!");
    }
  };

  const handlePluginDelete = async (data) => {

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `Plugins/${data.version + data.fileName}`);
      await deleteObject(storageRef);
    } catch (error) {
      setError(FilterText(error.message));
    }

    try {
      let deleteDocRef = doc(db, "Websites", "Client", "Plugins", data.id);
      await deleteDoc(deleteDocRef);
      fetchPlugins();
    } catch (error) {
      setError(FilterText(error.message));
    }
  };

  const handleAllUsers = (e) => {

    if (e.target.checked) {
      fetchUsers();
    } else {
      emailsRef.current.value = "";
    }
  }

  const sendMessage = (e) => {
    setIsClicked(true);
    e.preventDefault();

    if (
      emailsRef.current.value.trim() &&
      emailsRef.current.value.trim() !== ""
    ) {

      let data = JSON.stringify({
        "fromEmail": "info@tallykonnect.com",
        "toEmail": "info@tallykonnect.com",
        "subject": "Tallykonnect New Version Update",
        "text": "",
        "html": `
          <!doctype html>
            <html lang="en-US">
              <head>
                  <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
                  <title>New Version Update</title>
                  <meta name="description" content="New Version Update">
                  <style type="text/css">
                      a:hover {text-decoration: underline !important;}
                  </style>
              </head>

              <body marginheight="0" topmargin="0" marginwidth="0" style="margin: 0px; background-color: #f2f3f8;" leftmargin="0">
                  <table cellspacing="0" border="0" cellpadding="0" width="100%" bgcolor="#f2f3f8"
                      style="@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700|Open+Sans:300,400,600,700); font-family: 'Open Sans', sans-serif;">
                      <tr>
                          <td>
                              <table style="background-color: #f2f3f8; max-width:670px; margin:0 auto;" width="100%" border="0"
                                  align="center" cellpadding="0" cellspacing="0">
                                  <tr>
                                      <td style="height:80px;">&nbsp;</td>
                                  </tr>
                                  <tr>
                                      <td style="text-align:center;">
                                          <a href="https://tallykonnect.com/" title="logo" target="_blank">
                                          <img width="60" src="https://tallykonnect.com/static/media/logo.a8ed201928e329d7fc48.png" title="logo" alt="logo">
                                        </a>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td style="height:20px;">&nbsp;</td>
                                  </tr>
                                  <tr>
                                      <td>
                                          <table width="95%" border="0" align="center" cellpadding="0" cellspacing="0"
                                              style="max-width:670px; background:#fff; border-radius:3px; text-align:center;-webkit-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);-moz-box-shadow:0 6px 18px 0 rgba(0,0,0,.06);box-shadow:0 6px 18px 0 rgba(0,0,0,.06);">
                                              <tr>
                                                  <td style="height:40px;">&nbsp;</td>
                                              </tr>
                                              <tr>
                                                <tr>
                                                  <td style="padding:0 35px;">
                                                      <h1 style="color:#1e1e2d; font-weight:500; margin:0;font-size:32px;font-family:'Rubik',sans-serif;">New Version Update
                                                      </h1>
                                                      
                                                      <p style="font-size:15px; color:#455056; margin:8px 0 0; line-height:24px;">Please  update  your  plugin now,  to  avail  better and uninterrupted services.
              For more information ,  contact us  at tallykonnect.com<br></p>
                                                      <span
                                                          style="display:inline-block; vertical-align:middle; margin:29px 0 26px; border-bottom:1px solid #cecece; width:100px;"></span>
                                                  </td>
                                                </tr>
                                                <tr>
                                                    <td style="height:40px; width:10px;">Version: ${editData.version}</td>
                                                </tr>
                                                  <tr>
                                                    <td style="height:40px; width:10px;">Date of update: ${editData.uploadDate}</td>
                                                </tr>
                                                  <tr>
                                                    <td style="height:40px; width:10px;">Platform:${editData.platform}
                                                    </td>
                                                </tr>
                                                </tr>
                                                  <tr>
                                                    <td style="height:40px; width:10px;">
                                                      <a href="${editData.downloadURL}" target="_blank">
                                                        Download Link
                                                      </a>
                                                    </td>
                                                </tr>
                                              </tr>
                                              <tr>
                                                  <td style="height:40px;">&nbsp;</td>
                                              </tr>
                                          </table>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td style="height:20px;">&nbsp;</td>
                                  </tr>
                                  <tr>
                                      <td style="text-align:center;">
                                          <p style="font-size:14px; color:rgba(69, 80, 86, 0.7411764705882353); line-height:18px; margin:0 0 0;">&copy; <strong>tallykonnect.com</strong> </p>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td style="height:80px;">&nbsp;</td>
                                  </tr>
                              </table>
                          </td>
                      </tr>
                  </table>
              </body>

            </html>
        `,
        "bcc": emailsRef.current.value,
        "attachments": []
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://tallykonnect.com/mail/sendmail',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then((response) => {
          setIsClicked(false);
          setEditing(false);
          setEditData("");
        })
        .catch((error) => {
          console.log(error);
          setError(error.message);
          setIsClicked(false);
        });

    } else {
      setError("Enter atleast one email address")
    }
  };

  const fetchUsers = async () => {
    setIsProcessing(true);

    let emails = [];
    const q = query(collection(db, "Users"), orderBy("activationDate", "desc"));
    const snapshots = await getDocs(q);

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      if (doc.data()) {
        let {
          email
        } = doc.data();

        if (email) {

          emails.push(email);
        }


      }
    });

    const qch = query(collection(db, "Channel Partners"), orderBy("doj", "desc"));
    const snapshotsch = await getDocs(qch);

    snapshotsch.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      if (doc.data()) {
        let {
          email
        } = doc.data();

        if (email) {

          emails.push(email);
        }


      }
    });

    emailsRef.current.value = emails.join(",");
    setIsProcessing(false);
  };

  //BUSY INFO FETCH
  useEffect(() => {
    fetchPlugins();
  }, [fetchPlugins]);

  useEffect(() => {
    setTimeout(() => {
      setIsPending(false);
      setProgress(0);
      setMessage("");
      setError("");
      verRef.current.value = "";
      platformRef.current.value = "";
      bitRef.current.value = "";
    }, 5000);
  }, [message, error]);

  return (
    <>
      <div className="flex w-screen ">
        <Sidebar />
        <div className="body w-64 flex-1 bg-white p-8 h-screen overflow-y-auto">
          <div>
            {
              !editing && (
                <div className="w-full p-2 bg-gray-300">
                  <h1 className="bg-indigo-300 p-2 text-2xl font-thin text-white">
                    UPLOADS
                  </h1>
                  <div className="m-4 flex flex-col items-center">
                    <div className="rounded-md bg-white p-4 shadow-md">
                      <div className="flex flex-col space-y-2">
                        <p className="text-xl font-bold uppercase leading-8 tracking-wider text-gray-600">
                          UPLOAD
                        </p>
                        <form id="uploadForm" className="flex flex-col space-y-2">
                          <input
                            type="text"
                            name="platform"
                            id="platform"
                            className="rounded-xl border border-gray-600 p-2"
                            placeholder="Enter platform"
                            ref={platformRef}
                            required
                          />
                          <input
                            type="text"
                            name="version"
                            id="version"
                            className="rounded-xl border border-gray-600 p-2"
                            placeholder="Enter version"
                            ref={verRef}
                            required
                          />
                          <input
                            type="text"
                            name="bit"
                            id="bit"
                            className="rounded-xl border border-gray-600 p-2"
                            placeholder="Enter bit"
                            ref={bitRef}
                            required
                          />
                          <input
                            type="file"
                            name=""
                            id=""
                            disabled={isPending}
                            onChange={handleUpload}
                            required
                          />
                        </form>
                        <div className="">
                          <span className="mb-4 flex w-full items-center  justify-center space-x-2 rounded-xl bg-green-400 p-1">
                            <Upload color="white" />
                            {Math.floor(Progress)}%
                          </span>
                        </div>
                      </div>
                      <p className="md pt-4 font-bold text-red-600">{error}</p>
                    </div>
                  </div>
                </div>
              )
            }

            <div className="my-4">
              {message && <Alerts alertType="Info">{message}</Alerts>}
              {error && <Alerts alertType="Error">{error}</Alerts>}
            </div>

            {
              !editing && (
                <div className="my-4">
                  <h1 className="bg-indigo-300 p-2 text-2xl font-thin text-white">
                    UPLOAD
                  </h1>
                  <div className="w-full bg-gray-300 px-8">
                    <DataTable columns={columns} data={data} />
                  </div>
                </div>
              )
            }

            {
              editing && (
                <div className="w-full p-2 bg-gray-300">
                  <h1 className="bg-indigo-300 p-2 text-2xl font-thin text-white">
                    SEND NOTIFICATION
                  </h1>
                  <div className="flex flex-col space-y-2 w-full my-2 ">
                    <div className="w-[600px] m-auto">

                      <form id="uploadForm" className="flex flex-col space-y-2" onSubmit={sendMessage}>
                        <div className="my-2">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="rounded-xl border border-gray-600 p-2"
                            placeholder="Enter emails"
                            ref={emailsRef}
                            required
                          />
                        </div>
                        <div className="my-2">
                          <label htmlFor="allUsers" className="flex justify-start items-center space-x-2">
                            <span className="font-medium">
                              All Users
                            </span>

                            <input
                              type="checkbox"
                              name="allUsers"
                              id="allUsers"
                              className="rounded-xl border border-gray-600 p-2"
                              onChange={handleAllUsers}
                            />

                            {
                              isProcessing && <span className="font-bold text-md text-green-600 ">Please wait...</span>
                            }
                          </label>
                        </div>

                        <div className="flex justify-end space-x-2">
                          {
                            !isClicked && (
                              <button type="submit" className="bg-blue-500 rounded-full px-4 py-1 w-32 text-white font-medium">Submit</button>
                            )
                          }
                          {
                            isClicked && (
                              <button type="submit" className="bg-blue-300 rounded-full px-4 py-1 w-32 text-white font-medium disabled">Sending...</button>
                            )
                          }
                          <button type="button" className="bg-red-500 rounded-full px-4 py-1 w-32 text-white font-medium" onClick={() => setEditing(false)}>Cancel</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )
            }

          </div>
        </div>
      </div>
    </>
  );
}

export default Plugin;
