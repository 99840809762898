import React, { useEffect, useRef, useState } from "react";
import axios from 'axios';
import Sidebar from "../../components/Sidebar";
import DataTable from "../../components/DataTable"
import Alerts from "../../components/Alerts"
import FilterText from "../../utils/FilterText"
import { db } from "../../firebase";
import moment from "moment";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Edit, Visibility } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    query,
    doc,
    setDoc,
    addDoc,
    deleteDoc,
    collection,
    getDocs,
    orderBy,
    updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const Registers = () => {
    const [registers, setRegisters] = useState([]);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [editing, setEditing] = useState(false);

    const [formData, setFormData] = useState({
        "name": "",
        "emailId": "",
        "mobile": "",
        "executive": "",
        "status": "",
        "followupDate": "",
        "attained": "",
        "followupMessage": "",
    });

    const [processing, setProcessing] = useState("");
    const navigate = useNavigate();

    const columns = [
        {
            field: "TIMESTAMP",
            headerName: "Date",
            align: "left",
            width: "200"
        },
        {
            field: "name",
            headerName: "Name",
            align: "left",
            width: "180"
        },
        {
            field: "emailId",
            headerName: "Email",
            align: "left",
            width: "180"
        },
        {
            field: "mobile",
            headerName: "Mobile",
            align: "left",
            width: "120"
        },
        {
            field: "followupDate",
            headerName: "Follow Up Date",
            minWidth: 200,
            align: "center",
        },
        {
            field: "attained",
            headerName: "Attained Date",
            minWidth: 200,
            align: "center",
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 130,
            align: "center",
            format: (value) => value.toLocaleString("en-IN"),
        },
        {
            field: 'actions',
            type: 'actions',
            width: 80,
            headerName: "Actions",
            minWidth: 170,
            align: "right",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="Edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<Visibility />}
                    label="View"
                    onClick={() => navigate(
                        "/MobileFollowUp", { state: { ...params.row } }
                    )}
                />,
                <GridActionsCellItem
                    icon={<DeleteIcon />}
                    label="Delete"
                    onClick={() => handleDelete(params.row)}
                />,
            ]
        },

    ];

    const handleEdit = (data) => {
        setEditing(!editing);
        setFormData(data);
    };

    const handleDelete = async ({ id }) => {
        try {
            let docRef = doc(db, "Register", id);
            await deleteDoc(docRef);
            fetchAllRegister();
        } catch (error) {
            setMessage(FilterText(error.message))
        }
    };

    const handleChange = (e) => {
        setFormData((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setProcessing(true);

        let {
            name,
            emailId,
            mobile,
            executive,
            status,
            followupDate = "",
            attained = "",
            followupMessage = ""
        } = formData;

        try {
            let docRef = doc(db, "Register", formData.id);
            let colRef = collection(db, "Register", formData.id, "FollowUp");

            await updateDoc(docRef, {
                name,
                emailId,
                mobile,
                executive,
                status,
                followupDate,
                attained,
                followupMessage
            });

            if (
                followupDate !== "" &&
                followupMessage !== ""
            ) {
                await addDoc(colRef, {
                    followupDate,
                    followupMessage,
                    status,
                    executive,
                })
            }

            setEditing(!editing);
            setMessage("Record update successfully!");
            setProcessing(false);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchAllRegister = async () => {

        const q = query(collection(db, "Register"), orderBy("TIMESTAMP", "desc"));
        const snapshots = await getDocs(q);

        let records = [];
        let querySnapshotSize = snapshots.docs.length;
        let i = 0;

        if (querySnapshotSize > 0) {
            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                if (doc.data()) {

                    records.push({
                        id: doc.id,
                        ...doc.data(),
                        TIMESTAMP: moment(new Date(doc.data().TIMESTAMP?.toDate())).utc().format("DD-MM-YYYY HH:MM:SS A")
                    });
                }

                if (++i === querySnapshotSize) {
                    setRegisters(records);
                }
            });
        } else {
            setRegisters([]);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setMessage("");
            setError("");
        }, [5000])
    }, [error, message])

    useEffect(() => {
        fetchAllRegister();
    }, [editing])

    return (
        <div className="flex w-screen">
            <Sidebar />
            {editing && (
                <div className="body w-64 flex-1 bg-gray-200 p-8">
                    <p className="mb-4 w-full text-center font-sans text-xl text-red-500">
                        {FilterText(message)}
                    </p>
                    <div className="md:grid md:grid-cols-3 md:gap-6">
                        <div className="md:col-span-1">
                            <div className="px-4 sm:px-0">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Mobile Register Information
                                </h3>
                                <p className="mt-1 text-sm text-gray-600">
                                    Update Mobile Register
                                </p>
                            </div>
                        </div>
                        <div className="mt-5 md:col-span-2 md:mt-0">

                            <form onSubmit={handleUpdate}>
                                <div className="overflow-hidden shadow sm:rounded-md">
                                    <div className="bg-white px-4 py-5 sm:p-6">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <input
                                                    type="hidden"
                                                    name="id"
                                                    id="id"
                                                    value={formData.id}
                                                    onChange={handleChange}
                                                    autoComplete="id"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="name"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    className="mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="emailId"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Email
                                                </label>
                                                <input
                                                    type="text"
                                                    name="emailId"
                                                    id="emailId"
                                                    value={formData.emailId}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="mobile"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Mobile
                                                </label>
                                                <input
                                                    type="number"
                                                    name="mobile"
                                                    id="mobile"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="followupDate"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Follow Up Date
                                                </label>
                                                <input
                                                    type="datetime-local"
                                                    name="followupDate"
                                                    id="followupDate"
                                                    value={formData.followupDate}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="attained"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Attained
                                                </label>
                                                <input
                                                    type="datetime-local"
                                                    name="attained"
                                                    id="attained"
                                                    value={formData.attained}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="executive"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Executive (Full Name)
                                                </label>
                                                <input
                                                    type="text"
                                                    name="executive"
                                                    id="executive"
                                                    value={formData.executive}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                />
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="followupMessage"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Follow Up Message
                                                </label>
                                                <textarea
                                                    cols="30"
                                                    rows="4"
                                                    type="text"
                                                    name="followupMessage"
                                                    id="followupMessage"
                                                    value={formData.followupMessage}
                                                    onChange={handleChange}
                                                    className=" mt-1 block w-full border rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"

                                                >
                                                </textarea>
                                            </div>
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="status"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Status
                                                </label>
                                                <select
                                                    name="status"
                                                    id="status"
                                                    onChange={handleChange}
                                                    value={formData.status}
                                                    className="mt-1 block w-full rounded-md border-gray-300 p-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                >
                                                    <option value="">Please Select</option>
                                                    <option value="Pending">Pending</option>
                                                    <option value="Follow Up">Follow Up</option>
                                                    <option value="Not Interested">Not Interested</option>
                                                    <option value="Done">Done</option>

                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                                        {!processing ? (
                                            <button
                                                type="submit"
                                                value="Submit"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Save
                                            </button>
                                        ) : (
                                            <button
                                                disabled={processing}
                                                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            >
                                                Wait...
                                            </button>
                                        )}
                                        <button
                                            type="submit"
                                            value="Submit"
                                            onClick={() => setEditing(false)}
                                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            {
                !editing && (
                    <div className="container max-auto p-12">
                        <h1 className="text-md font-serif bg-blue-300 p-2 rounded-md m-auto text-center w-1/4 text-white mb-4">REGISTERS</h1>
                        {
                            error && <Alerts alertType="error">{error}</Alerts>
                        }
                        {
                            registers && registers.length > 0 && <DataTable data={registers} columns={columns} />
                        }
                        {
                            !registers && (
                                <Alerts alertType="Info" >{message}</Alerts>
                            )
                        }


                    </div>
                )
            }
            <Alerts alertType="Error">{error}</Alerts>

        </div>
    )
}

export default Registers;