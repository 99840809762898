import axios from "axios";
import readXlsxFile from "read-excel-file";
import React, { useEffect, useState, useRef, useCallback } from "react";
import Sidebar from "../../components/Sidebar";
import { Download } from "@mui/icons-material";
import Emojis from "../../utils/Emojis";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import "../../App.css"
import Alerts from "../../components/Alerts";
import { db } from "../../firebase";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import sampleFile from "/root/app/TallyKonnect/admin/src/pages/mail/sample.xlsx";


const Mail = () => {

    const [isProcessing, setIsProcessing] = useState(false);

    const [message, setMessage] = useState("");
    const [status, setStatus] = useState("");
    const [error, setError] = useState("");
    const [selectedFile, setSelectedFile] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [isFileImportSelected, setIsFileImportSelected] = useState(false);
    const fileImportData = useRef([]);

    const [sentMessage, setSentMessage] = useState(0);
    const [totalEmails, setTotalEmails] = useState(0);

    const [allUsers, setAllUsers] = useState(false);
    const [allOwnUsers, setOwnUsers] = useState(false);
    const [allResellers, setResellers] = useState(false);
    const [resellersUser, setResellersUser] = useState(false);

    const [isClicked, setIsClicked] = useState(false);

    const emailsRef = useRef();
    const fileRef = useRef();
    const mailsFile = useRef();

    const [jsonData, setJsonData] = useState({
        fromEmail: "",
        toEmail: "",
        subject: "",
        html: "",
        attachments: []
    });

    const handleExcelFile = async (e) => {
        const map = {
            Name: "Name",
            Mail: "Mail",
            Message: "Message",
        };

        let file = mailsFile.current.files[0];

        let mails = [];

        await readXlsxFile(file, { map }).then(({ rows }) => {
            rows.forEach((row) => {
                mails.push(row.Mail.toString().trim());
                fileImportData.current.push(row);
            });

            if (fileImportData.current.length > 0) {
                setIsFileImportSelected(true);
            }
        });

        emailsRef.current.value = mails.join(",");
        //SET Remain Msg to be send
        setTotalEmails(Number(mails.length));
    };

    const resetAllParameters = () => {
        setStatus(null);
        setError(null);
        setIsClicked(false);
        setSelectedFile(null);
        setIsSelected(false);
        setTotalEmails(0);
        setSentMessage(0);
        setMessage(null);
        // fileRef.current.value = null;
        // mailsFile.current.value = null;
        // messageRef.current.value = null;
        // emailsRef.current.value = null;
    };

    const handleUserSelection = async (e) => {

        let emails = [];

        setAllUsers(e.target.checked);

        if (e.target.checked) {
            setIsProcessing(true);

            const q = query(collection(db, "Users"), orderBy("activationDate", "desc"));
            const snapshots = await getDocs(q);

            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                if (doc.data()) {
                    let {
                        email
                    } = doc.data();

                    if (email) {

                        emails.push(email);
                    }
                }
            });

            const qch = query(collection(db, "Channel Partners"), orderBy("doj", "desc"));
            const snapshotsch = await getDocs(qch);

            snapshotsch.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                if (doc.data()) {
                    let {
                        email
                    } = doc.data();

                    if (email) {

                        emails.push(email);
                    }

                }
            });

            emailsRef.current.value = emails.join(",");

            setIsProcessing(false);
        } else {
            emailsRef.current.value = [];
        }

        setTotalEmails(emails.length);
    }

    const handleOwnUserSelection = async (e) => {
        let emails = [];

        setOwnUsers(e.target.checked);

        if (e.target.checked) {
            setIsProcessing(true);

            const q = query(collection(db, "Users"), orderBy("activationDate", "desc"));
            const snapshots = await getDocs(q);

            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                let data = doc.data();

                if (
                    data &&
                    data.channelPartnerRefferalId === ""
                ) {
                    let {
                        email
                    } = data;

                    if (email) {

                        emails.push(email);
                    }
                }
            });

            emailsRef.current.value = emails.join(",");

            setIsProcessing(false);
        } else {
            emailsRef.current.value = [];
        }

        setTotalEmails(emails.length);
    }

    const handleResellersSelection = async (e) => {
        let emails = [];

        setResellers(e.target.checked);

        if (e.target.checked) {
            setIsProcessing(true);

            const q = query(collection(db, "Channel Partners"), orderBy("doj", "desc"));
            const snapshots = await getDocs(q);

            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                let data = doc.data();

                if (
                    data
                ) {
                    let {
                        email
                    } = data;

                    if (email) {

                        emails.push(email);
                    }
                }
            });

            emailsRef.current.value = emails.join(",");

            setIsProcessing(false);
        } else {
            emailsRef.current.value = [];
        }

        setTotalEmails(emails.length);
    }

    const handleResellersUserSelection = async (e) => {
        let emails = [];

        setResellersUser(e.target.checked);

        if (e.target.checked) {
            setIsProcessing(true);

            const q = query(collection(db, "Users"), orderBy("activationDate", "desc"));
            const snapshots = await getDocs(q);

            snapshots.forEach((doc) => {
                //FORMATE DATE OF JOINING START

                let data = doc.data();

                if (
                    data &&
                    data.channelPartnerRefferalId !== ""
                ) {
                    let {
                        email
                    } = data;

                    if (email) {

                        emails.push(email);
                    }
                }
            });

            emailsRef.current.value = emails.join(",");

            setIsProcessing(false);
        } else {
            emailsRef.current.value = [];
        }

        setTotalEmails(emails.length);
    }

    useEffect(() => {
        if ((status !== null || error !== null) && sentMessage === totalEmails) {
            setTimeout(() => {
                resetAllParameters();
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, status, sentMessage]);

    const sendMessage = () => {
        setIsClicked(true);

        //CHECK VALID MAILS BEFORE SENDING
        //SEND MAIL

        let data = JSON.stringify({
            "fromEmail": jsonData.fromEmail,
            "toEmail": jsonData.toEmail,
            "subject": jsonData.subject,
            "text": "",
            "html": jsonData.message,
            "bcc": jsonData.bcc,
            "attachments": []
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: 'https://tallykonnect.com/mail/sendmail',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                setMessage(response.data.message);
                setJsonData({
                    "fromEmail": "",
                    "toEmail": "",
                    "subject": "",
                    "text": "",
                    "html": "",
                    "attachments": []
                });
                setIsClicked(false);
            })
            .catch((error) => {
                setError(error.message);
                setIsClicked(false);
            });


    };

    // eslint-disable-next-line no-extend-native
    String.prototype.insert = function (index, string) {
        if (index > 0) {
            return this.substring(0, index) + string + this.substr(index);
        }

        return string + this;
    };

    const handleChange = (e) => {

        setJsonData((prevData) => {
            return { ...prevData, [e.target.name]: e.target.value };
        });
    }

    const API_URL = "https://tallykonnect.com";

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const data = new FormData();
                    loader.file.then((file) => {
                        data.append("file", file);

                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: 'https://tallykonnect.com/file/upload',
                            header: {
                                "Content-Type": "multipart/form-data",
                                'Access-Control-Allow-Origin': '*',
                            },

                            data: data
                        };

                        axios.request(config)
                            .then((response) => {

                                let res = response.data;

                                resolve({
                                    default: `${API_URL}/${res.filename}`
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                                reject(error.message);
                            });

                    });
                });
            }
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        setIsSelected(true);
    };

    const sendFileMessage = () => {
        setIsClicked(true);

        const apiURL = `https://tallykonnect.com/mail/sendattachmentmail`;
        const formData = new FormData();

        formData.append("fromEmail", jsonData.fromEmail);

        formData.append("subject", jsonData.subject);
        formData.append("text", "");
        formData.append("html", jsonData.message);

        formData.append("file", selectedFile);

        if (jsonData.toEmail.includes(",")) {
            formData.append("toEmail", "info@tallykonnect.com");
            formData.append("bcc", jsonData.toEmail || emailsRef.current.value);
        } else {
            formData.append("toEmail", jsonData.toEmail || emailsRef.current.value);
            formData.append("bcc", jsonData.bcc);
        }

        fetch(apiURL, {
            method: "POST",
            headers: {},
            body: formData,
        })
            .then((response) => response.json())
            .then((result) => {
                let msgResp = result.message;

                setMessage(msgResp);

                setJsonData({
                    "fromEmail": "",
                    "toEmail": "",
                    "subject": "",
                    "text": "",
                    "html": "",
                    "attachments": []
                });

                setIsClicked(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsClicked(false);
                setError("You are not authorized to make this request");
            });
    };

    useEffect(() => {
        if (message) {

            console.log("message", message)
            setTimeout(() => {
                setMessage("");
                setJsonData(null);
            }, 3000)

            setTimeout(() => {
                window.location.reload(false);
            }, 5000)
        }
    }, [message])

    return (
        <div className="flex w-screen ">
            <Sidebar />
            <div className="body w-full flex-1 p-8 h-screen overflow-y-scroll">
                <div className="w-full">
                    <div className="w-10/12 rounded-md bg-blue-300 p-2">
                        <div className="head">
                            <h1 className="mb-4 text-center text-xl font-bold tracking-wide text-white">
                                SEND MULTI MESSAGE
                            </h1>
                            <hr className="mb-2" />
                            {status !== "" && (
                                <p className="text-center text-xl tracking-normal text-green-500 ">
                                    {status}
                                </p>
                            )}
                            {error !== "" && (
                                <p className="text-center text-xl tracking-normal text-red-500">
                                    {error}
                                </p>
                            )}
                        </div>
                        <div className="body flex flex-col items-center justify-center">
                            <div
                                action=""
                                method="post"
                                className="flex w-10/12 flex-col space-y-4"
                            >
                                <div>
                                    <p className="inline-block text-xs font-bold uppercase leading-8 tracking-wider text-white">
                                        Download Sample Excel File
                                    </p>
                                    <a
                                        href={sampleFile}
                                        download="sample"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="text-md ml-4 h-8 w-8 rounded-full bg-white font-semibold leading-8 text-blue-400"
                                    >
                                        <Download />
                                    </a>
                                </div>
                                <div className="flex justify-between text-white">
                                    <label
                                        htmlFor="phone"
                                        className="text-xl font-thin text-white"
                                    >
                                        FROM EMAIL:
                                    </label>
                                    <input
                                        type="file"
                                        id="input"
                                        ref={mailsFile}
                                        onChange={handleExcelFile}
                                        accept=".xlsx"
                                        disabled={isClicked}
                                    />
                                </div>
                                {jsonData && (

                                    <div>
                                        <select id="fromEmail" name="fromEmail" onChange={handleChange} value={jsonData.fromEmail} className="p-2">
                                            <option value="">Please Select</option>
                                            <option value="admin@tallykonnect.com">admin@tallykonnect.com</option>
                                            <option value="info@tallykonnect.com">info@tallykonnect.com</option>
                                            <option value="sales@tallykonnect.com">sales@tallykonnect.com</option>
                                            <option value="support@tallykonnect.com">support@tallykonnect.com</option>
                                        </select>
                                    </div>
                                )}
                                <div>
                                    <input
                                        type="text"
                                        name="toEmail"
                                        id="toEmail"
                                        className=" w-full rounded-md p-2"
                                        onChange={handleChange}
                                        ref={emailsRef}
                                        placeholder="TO EMAIL"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="email"
                                        name="bcc"
                                        id="bcc"
                                        className=" w-full rounded-md p-2"
                                        onChange={handleChange}
                                        placeholder="BCC"
                                    />
                                </div>
                                <div>
                                    <input
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        className=" w-full rounded-md p-2"
                                        onChange={handleChange}
                                        placeholder="SUBJECT"
                                    />
                                </div>

                                <div className="flex justify-start items-center space-x-2">

                                    <label
                                        htmlFor="users"
                                        className="text-xl font-thin text-white"
                                    >
                                        <span className="mx-2">All Users:</span>
                                        <input type="checkbox" name="users" id="users" onChange={handleUserSelection} className="mr-2" disabled={allOwnUsers || allResellers || resellersUser} />

                                    </label>
                                    <label
                                        htmlFor="users"
                                        className="text-xl font-thin text-white"
                                    >
                                        <span className="mx-2">Own Users:</span>
                                        <input type="checkbox" name="users" id="users" onChange={handleOwnUserSelection} className="mr-2" disabled={allUsers || allResellers || resellersUser} />

                                    </label>
                                    <label
                                        htmlFor="users"
                                        className="text-xl font-thin text-white"
                                    >
                                        <span className="mx-2">Resellers:</span>
                                        <input type="checkbox" name="users" id="users" onChange={handleResellersSelection} className="mr-2" disabled={allUsers || allOwnUsers || resellersUser} />

                                    </label>
                                    <label
                                        htmlFor="users"
                                        className="text-xl font-thin text-white"
                                    >
                                        <span className="mx-2">Reseller's User:</span>
                                        <input type="checkbox" name="users" id="users" onChange={handleResellersUserSelection} className="mr-2" disabled={allUsers || allOwnUsers || allResellers} />

                                    </label>
                                </div>
                                <div className="flex justify-start items-center space-x-2">
                                    {isProcessing && allUsers && (<span className="text-black font-sm">Please Wait...</span>)}
                                    {isProcessing && allOwnUsers && (<span className="text-black font-sm">Please Wait...</span>)}
                                    {isProcessing && allResellers && (<span className="text-black font-sm">Please Wait...</span>)}
                                    {isProcessing && resellersUser && (<span className="text-black font-sm">Please Wait...</span>)}
                                </div>
                                <div className="flex justify-start items-center space-x-2">
                                    <span className="font-sans text-md text-white">Total Emails: </span>
                                    <span className="text-white">{totalEmails}</span>
                                </div>
                                <CKEditor
                                    config={{
                                        extraPlugins: [uploadPlugin]
                                    }}
                                    editor={ClassicEditor}
                                    data="<p></p>"
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();

                                        setJsonData((prevData) => {
                                            return { ...prevData, message: data };
                                        });
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        // console.log('Focus.', editor);
                                    }}
                                />

                                <label htmlFor="file" className="text-xl font-thin text-white">
                                    Upload File:
                                </label>
                                <input
                                    type="file"
                                    name="file"
                                    id="file"
                                    onChange={changeHandler}
                                    ref={fileRef}
                                    disabled={isClicked}
                                />

                                {isSelected && selectedFile ? (
                                    <div>
                                        <p>
                                            Filename: {selectedFile.name ? selectedFile.name : ""}
                                        </p>
                                        <p>
                                            Filetype: {selectedFile.type ? selectedFile.type : ""}
                                        </p>
                                        <p>
                                            Size in bytes:{" "}
                                            {selectedFile.size ? selectedFile.size : ""}
                                        </p>
                                        <p>
                                            lastModifiedDate:{" "}
                                            {selectedFile.lastModifiedDate
                                                ? selectedFile.lastModifiedDate.toLocaleDateString()
                                                : ""}
                                        </p>
                                    </div>
                                ) : (
                                    <p>Select a file to show details</p>
                                )}

                                <div className="p-2 text-right">
                                    <button
                                        className="rounded-md bg-yellow-400 py-2 px-12 text-white"
                                        onClick={isSelected ? sendFileMessage : sendMessage}
                                        disabled={isClicked || error === ""}
                                    >
                                        {isClicked ? "Sending..." : "Send"}
                                    </button>
                                </div>
                                {message !== "" && <Alerts alertType="Info">{message}</Alerts>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Emojis getSelectedEmoji={(data) => getSelectedEmoji(data)} /> */}
        </div>
    );
};

export default Mail;
