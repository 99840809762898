import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";


import { db } from "../../firebase";
import { collection, query, getDocs, orderBy } from "firebase/firestore";

import ShopIcon from "@mui/icons-material/Shop";
import DataTable from "../../components/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Visibility } from "@mui/icons-material";
import moment from "moment/moment";

function Order() {
  const [data, setData] = useState([]);
  const originalDataRef = useRef([]);
  let navigate = useNavigate();

  const columns = [
    { field: "created_at", headerName: "Date", minWidth: 170, align: "left" },
    { field: "receipt", headerName: "Receipt No", minWidth: 170, align: "left" },
    { field: "personalName", headerName: "Name", minWidth: 170, align: "left" },
    { field: "businessName", headerName: "Business", minWidth: 170, align: "left" },
    { field: "gstin", headerName: "GSTIN", minWidth: 170, align: "left" },
    { field: "email", headerName: "Email", minWidth: 170, align: "left" },
    { field: "mobile", headerName: "Mobile", minWidth: 170, align: "left" },
    { field: "plan", headerName: "Plan", minWidth: 170, align: "left" },
    { field: "price", headerName: "Price", minWidth: 170, align: "left" },
    { field: "discountAmount", headerName: "Discount Amount", minWidth: 170, align: "left" },
    { field: "internetHandlingCharge", headerName: "Internet Charge", minWidth: 170, align: "left" },
    { field: "gst", headerName: "GST", minWidth: 170, align: "left" },
    {
      field: "grossAmount",
      headerName: "Gross Amount",
      minWidth: 130,
      align: "left",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      minWidth: 130,
      align: "left",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 130,
      align: "left",
      format: (value) => value.toLocaleString("en-IN"),
    },
    {
      field: "amount_paid",
      headerName: "Paid Amount",
      minWidth: 130,
      align: "center",
    },
    {
      field: "benefits",
      headerName: "Benefits",
      minWidth: 130,
      align: "right",
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      align: "center",
    }
  ];


  const fetchData = async () => {
    //USER QUERY
    let i = 0;
    const colRef = query(collection(db, "Websites", "Admin", "Orders"), orderBy('created_at', 'desc'));

    const querySnapshot = await getDocs(colRef);
    let records = [];
    let querySnapshotSize = querySnapshot.docs.length;

    querySnapshotSize > 0 && querySnapshot.docs.forEach((doc) => {
      let orderDetails = doc.data();

      records.push({
        id: doc.id,
        ...orderDetails,
        created_at: moment(new Date(orderDetails.created_at * 1000)).utc().format("DD-MM-YYYY"),
      });

      if (++i === querySnapshotSize) {
        setData(records);
        originalDataRef.current = records;
      }
    });


  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex w-screen">
      <Sidebar />
      <div className="container mx-auto p-12">
        {data && (
          <div>
            <h1 className="mb-4 rounded bg-indigo-600 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
              All Orders
            </h1>
            <DataTable columns={columns} data={data} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Order;
