import React, { useEffect, useState } from 'react'
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useLocation } from 'react-router-dom'
import Sidebar from '../../components/Sidebar';
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const columns = [
    { id: "followupDate", label: "Follow Up Date", minWidth: 170, align: "left" },
    { id: "followupMessage", label: "Follow Up Message", minWidth: 170, align: "left" },
    { id: "executive", label: "Executive", minWidth: 170, align: "left" },
    { id: "status", label: "Status", minWidth: 170, align: "left" },
];

function MobileFollowUp() {
    let location = useLocation();
    const [page, setPage] = useState(0);
    const [data, setData] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const followUpDetail = location.state;

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        let val = e.target.value ? +e.target.value : 0;
        setRowsPerPage(val);
        setPage(0);
    };

    //FETCH ALL DEMO REQUESTS
    const fetchAllDemo = async () => {
        const followUpDetail = location.state;
        const records = []

        // const doc = await db.collection("Register").doc(followUpDetail.id);
        const docRef = doc(db, "Register", followUpDetail.id);

        const contactData = await getDoc(docRef)

        if (contactData.exists()) {


            let contactsData = contactData.data()

            if (contactsData) {

                if (contactsData) {
                    let {
                        followupDate,
                        followupMessage,
                        TIMESTAMP: originalDateTime,
                        status,
                        executive
                    } = contactsData

                    records.push({
                        id: doc.id,
                        followupDate: moment(followupDate).utc()
                            .tz("Asia/Kolkata")
                            .format("DD-MM-YYYY h:mm:ss A"),
                        followupMessage,
                        originalDateTime,
                        status,
                        executive
                    });

                    setData(records);
                }
            }
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
            setData([]);
        }
    };

    useEffect(() => {
        fetchAllDemo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="flex w-screen">
            <Sidebar />
            {
                data && (
                    <div className="body w-64 flex-1 bg-white p-8">
                        <h1 className="mb-4 rounded bg-indigo-600 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
                            DEMO REQUESTS FOLLOW UP DETAILS
                        </h1>

                        <div className='my-4'>
                            <div className='grid grid-cols-2 w-[400px]'>
                                <div className='font-serif'>NAME :</div>
                                <div>{followUpDetail.name}</div>
                            </div>
                            <div className='grid grid-cols-2 w-[400px]'>
                                <div className='font-serif'>EMAIL :</div>
                                <div>{followUpDetail.emailId}</div>
                            </div>
                            <div className='grid grid-cols-2 w-[400px]'>
                                <div className='font-serif'>CONTACT NO :</div>
                                <div>{followUpDetail.mobile}</div>
                            </div>
                            <div className='grid grid-cols-2 w-[400px]'>
                                <div className='font-serif'>REQUESTED DATE TIME :</div>
                                <div>{followUpDetail.TIMESTAMP}</div>
                            </div>
                        </div>

                        {data && <Paper sx={{ width: "100%", overflow: "hidden", backgroundColor: "#eef2ff" }}>

                            <div>
                                <TableContainer sx={{ maxHeight: 500 }}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                {columns.map((column) => (
                                                    <StyledTableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                    >
                                                        {column.label}
                                                    </StyledTableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data &&
                                                data.length > 0 &&
                                                data
                                                    .slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage + rowsPerPage
                                                    )
                                                    .map((row) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={row.id}
                                                            >
                                                                {columns.map((column) => {
                                                                    const value = row[column.id];
                                                                    return (
                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                        >
                                                                            {column.format &&
                                                                                typeof value === "number" ? (
                                                                                column.format(value)
                                                                            ) : (
                                                                                value
                                                                            )}
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 100]}
                                    component="div"
                                    count={data.length}
                                    rowsPerPage={rowsPerPage ? rowsPerPage : 0}
                                    page={page ? page : 0}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        </Paper>}
                        {!data && <p>No record found :(</p>}

                    </div>
                )
            }
        </div>
    )
}

export default MobileFollowUp