import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";

import Sidebar from "../../components/Sidebar";
import { Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import moment from "moment";
import axios from "axios";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DataTable from "../../components/DataTable";
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff';
import VoiceOverOffIcon from '@mui/icons-material/VoiceOverOff';
import Alerts from "../../components/Alerts";

export default function ChannelPartner() {

  const [data, setData] = useState([]);
  const originalDataRef = useRef([]);

  const [message, setMessage] = useState("");

  let navigate = useNavigate();

  const fetchUsers = async () => {

    const q = query(collection(db, "Channel Partners"), orderBy("doj", "desc"));
    const snapshots = await getDocs(q);

    let records = [];
    let querySnapshotSize = snapshots.docs.length;
    let i = 0;

    snapshots.forEach((doc) => {
      //FORMATE DATE OF JOINING START

      let {
        email,
        mobile,
        password,
        doj,
        city,
        firstName = "",
        personalName = "",
        businessName,
        licenceInfo: { plan } = "",
        myReferalCode,
        isDisabled = false
      } = doc.data();

      if (doc.data()) {

        records.push({
          id: doc.id,
          personalName: firstName ? firstName : personalName,
          businessName,
          mobile,
          email,
          password,
          dateOfJoining:
            doj !== ""
              ? moment(Number(doj)).utc().format("DD-MM-YYYY")
              : "",
          city,
          planType: plan,
          myReferalCode,
          isDisabled
        });
      }

      if (++i === querySnapshotSize) {

        setData(records);
        originalDataRef.current = records;
      }
    });
  };

  const handleDelete = useCallback(async (data) => {
    data = JSON.stringify({
      uid: data.id,
    });

    var config = {
      method: "post",
      url: "https://tallykonnect.com/auth/removeuser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        fetchUsers();
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const updateUserStatus = (profile) => {

    let data = JSON.stringify({
      "uid": profile.id,
      "isDisabled": !profile.isDisabled
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://tallykonnect.com/auth/channelpartnersstatus',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        setMessage(response.data.message);
        setData([]);
        fetchUsers();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const columns = useMemo(
    () => [
      { field: "myReferalCode", headerName: "Referal Code", minWidth: 210, align: "left" },
      { field: "businessName", headerName: "Business", minWidth: 210, align: "left" },
      { field: "personalName", headerName: "Name", minWidth: 210, align: "left" },
      { field: "email", headerName: "Email", minWidth: 210, align: "left" },
      { field: "mobile", headerName: "Mobile", minWidth: 100, align: "left" },
      { field: "password", headerName: "Password", minWidth: 100, align: "left" },
      { field: "city", headerName: "City", minWidth: 100, align: "left" },
      {
        field: "dateOfJoining",
        headerName: "D.O.J",
        minWidth: 120,
        align: "left",
        format: (value) => value.toLocaleString("en-IN"),
      },
      {
        field: "isDisabled",
        headerName: "Status",
        minWidth: 120,
        align: "left",
      },
      {
        field: 'actions',
        type: 'actions',
        width: 80,
        headerName: "Actions",
        minWidth: 170,
        align: "right",
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              params.row && params.row.isDisabled ? (
                <VoiceOverOffIcon style={{ color: "red" }} />
              ) : (
                <SpatialAudioOffIcon style={{ color: "green" }} />
              )
            }
            label="Disable"
            onClick={() => updateUserStatus(params.row)}
          />,
          <GridActionsCellItem
            icon={<Visibility />}
            label="View"
            onClick={() => navigate(
              "/channelpartnerprofile", { state: { ...params.row } }
            )}
          />,
        ]
      },
    ], [navigate, handleDelete]);

  useEffect(() => {
    fetchUsers();
  }, [])

  return (
    <div className="flex h-screen w-screen">
      <Sidebar />
      <div className="body w-64 flex-1 flex-col bg-white p-8">
        <h1 className="mb-4 rounded bg-indigo-600 p-2 text-center text-2xl font-semibold tracking-wider text-white shadow-xl">
          Channel Partners
        </h1>
        <Alerts alertType="Info">{message}</Alerts>
        {data.length >= 0 ? (
          <DataTable columns={columns} data={data} />
        ) : (
          <p className="text-xl font-semibold text-red-500 ">
            no records found
          </p>
        )}
      </div>
    </div>
  );
}

