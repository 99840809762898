import React, { useState, useEffect } from "react";

import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";

import Sidebar from "../../components/Sidebar";
import profile from "./profile.png";

import { useLocation } from "react-router-dom";
import moment from "moment";
import FilterText from "../../utils/FilterText";

function ChannelPartnerProfile() {
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);

  const location = useLocation();
  const {
    id: uid,
  } = location.state;

  console.log("location", location.state);

  let fetchProfile = async () => {
    let userProfileData = null;
    let docRef = doc(db, "Channel Partners", uid);
    userProfileData = await getDoc(docRef);

    if (userProfileData.exists) {
      console.log("userProfile", userProfileData.data());
      setUserProfile(userProfileData.data());
    }
  };

  useEffect(() => {
    if (uid) fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  useEffect(() => {
    setTimeout(() => {
      setError("");
      setSuccessMessage("");
    }, 1000);
  }, [error, successMessage]);

  return (
    <div className="flex w-screen ">
      <Sidebar />
      {!loading && userProfile && (
        <div className="body w-64 flex-1 bg-indigo-100 p-8">
          <h1 className="mb-8 text-left text-4xl font-thin uppercase leading-10 text-black">
            {userProfile.personalName}
          </h1>
          <div className="flex">
            <div className="flex-1">
              <div className="items-left flex flex-col justify-center space-y-1">
                <img className="mb-8 w-24 rounded-full" src={profile} alt="" />

                <p className="text-xl font-thin uppercase text-indigo-600">
                  Name:
                  <span className="ml-2 text-black">
                    {userProfile.personalName}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-indigo-600">
                  User ID:
                  <span className=" ml-2 normal-case text-black">
                    {userProfile.uid}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-indigo-600">
                  Joining Date:
                  <span className=" ml-2 text-black">
                    {moment(new Date(Number(userProfile.doj)))
                      .utc()
                      .format("DD-MM-YYYY")}
                  </span>
                </p>
              </div>
            </div>

            <div className="flex-1">
              <div className="mb-4">
                <p className="text-xl font-thin uppercase text-indigo-600">
                  Username:
                  <span className="ml-2 lowercase text-black">
                    {userProfile.email}
                  </span>
                </p>
                <p className="text-xl font-thin uppercase text-indigo-600">
                  Password:
                  <span className=" ml-2 normal-case text-black">
                    {userProfile.password}
                  </span>
                </p>
              </div>
              <h1 className="text-xl font-thin uppercase text-indigo-600">
                ADDRESS
              </h1>
              <p className="text-xl font-thin uppercase text-black">
                {userProfile.city}
              </p>
              <p className="text-xl font-thin uppercase text-black">
                {userProfile.mobile}
              </p>
              <p className="text-xl font-thin uppercase text-black">
                {userProfile.email}
              </p>
            </div>
          </div>
          <hr className="mt-4 mb-4" />

          <hr className="mt-4 mb-4" />
          <p className="mb-4 text-center text-xl font-bold text-red-600">
            {error && error !== ""
              ? FilterText(error)
              : successMessage && successMessage !== ""
                ? FilterText(successMessage)
                : ""}
          </p>
        </div>
      )}
    </div>
  );
}

export default ChannelPartnerProfile;
